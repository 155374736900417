import MDTypography from "components/MDTypography"
import style from "./question.css"
import { MdOutlineDelete, MdRemoveCircleOutline } from "react-icons/md"


function Question(props){
    const primeiro_x_caracteres = ()=>{
        if (props.title.length<27){
            return props.title.substring(0, 36)
        } else {
            return props.title.substring(0, 36)+"..."
        }
        
    }

    return (
        <div className="question-box">
            <div onClick={props.onClick} className = "coluna1">
                <div className="order">{props.question_list.indexOf(props.object) + 1}</div>
                <div className="question-item">
                    <MDTypography variant="h6" color="dark" className="question">{primeiro_x_caracteres()}</MDTypography>
                    <div className="type">{props.type}</div>
                </div>
            </div>
            <div>
                {props.type !== "thankyou" &&
                    <MdOutlineDelete
                        className="delete-question"
                        onClick = {props.onClick_delete}
                        title="Remove Item"
                    />
                }
            </div>
        </div>
    );
}

export default Question;