import style from "./image_selector.css"

import { MdCloudUpload, MdOutlineDelete, MdSave} from "react-icons/md"
import { useState } from "react";


function Image_selector(props){

    const [img_blob, setImg_blob] = useState("")
    const [texto, setTexto] = useState("")


    const abcd = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]

    function appendOption(){
        props.setOptions([...props.options, {"image": img_blob, "description":texto}]);
        
        /*Recetamos o valor do  input e dos states*/
        setImg_blob("");
        setTexto("");
        const elemento = document.getElementById("formchelo-new-image-ps");
        elemento.value = "";
    };

    const handleChange = e =>{
        setTexto(e.target.value)
    }

    function selectImage() {
        return new Promise((resolve, reject) => {
            // Criar um input do tipo file
            var input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*'; // Aceitar apenas arquivos de imagem
    
            // Adicionar um evento de mudança para o input
            input.addEventListener('change', function(event) {
                var file = event.target.files[0]; // Obter o arquivo selecionado
    
                // Verificar se o arquivo é uma imagem
                if (file && file.type.startsWith('image/')) {
                    // Ler o conteúdo do arquivo como uma URL de dados
                    var reader = new FileReader();
                    reader.onload = function() {
                        // Resolver a promise com a URL da imagem
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    // Se o arquivo não for uma imagem, rejeitar a promise
                    reject(new Error('Por favor, selecione um arquivo de imagem válido.'));
                }
            });
    
            // Disparar o clique no input para abrir o seletor de arquivos
            input.click();
        });
    }

    function Option(props){

        return (
            <div className="image-option-box">
                <div className="delete">
                    <MdOutlineDelete
                        onClick={()=>{props.setOptions(props.options.filter(item=> item !== props.item))}}
                        title="delete"
                    />
                </div>
                <div className="image">
                    <img src={props.image_src}/>
                </div>
                <div className="legenda">
                    <div className="index">{props.index}</div>
                    <div className="text">{props.text}</div>
                </div>
            </div>
        )
    }

    return( 
        <div className="image-content-box">
            <div className="options">
                {props.options.map((op)=>{
                    return (
                        <>
                            <Option
                                options = {props.options}
                                setOptions = {props.setOptions}
                                item = {op}
                                index = {abcd[props.options.indexOf(op)]}
                                image_src = {op.image} 
                                text = {op.description}
                            />
                        </>
                    )
                })}
                <div className="image-option-box">
                    <div className="save">
                        <MdSave
                            onClick={appendOption}
                            title="Save"
                        />
                    </div>
                    <div className="image">
                        {img_blob.length>0
                            ? <img src = {img_blob}
                                onClick={()=>{
                                    selectImage()
                                    .then((imagePath) => {setImg_blob(imagePath)})
                                    .catch((error) => {console.error(error.message)})
                                }}
                            />
                            :<MdCloudUpload 
                                className="img-upload-btn"
                                size={100}
                                title = "Upload Image"
                                onClick={()=>{
                                    selectImage()
                                    .then((imagePath) => {setImg_blob(imagePath)})
                                    .catch((error) => {console.error(error.message)})
                                }}
                            />
                        }
                    </div>
                    <div className="legenda">
                        <input
                            onChange = {handleChange}
                            id="formchelo-new-image-ps"
                            name="dgfhfhj"
                            type="text"
                            placeholder="Description..."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Image_selector