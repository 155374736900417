import style from "./list_selector.css"
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useState } from "react"

function List_selector(props) {

    const  [selected, setSelected] = useState([])
    
    return (
        <div  className="list-selector-box">
            <div className="options">
                {props.options.map((item)=>{
                    return (
                        <div>
                            <FormGroup>
                                <FormControlLabel sx={props.sx} 
                                control={
                                    <Checkbox value={item} onChange={e=>{
                                        if(e.target.checked){
                                            setSelected([...selected, e.target.value])
                                        } else{
                                            setSelected(selected.filter(item=> item !== e.target.value))
                                        }
                                    }}/>
                                } 
                                label={item} />
                            </FormGroup>
                        </div>
                    )
                })}
            </div>
            <div>
                <button style={props.btn_style} id="btn" onClick = {()=>props.handleMultiselct(selected)}>Continuar</button>
            </div>
        </div>
    );
}


export default  List_selector