import style from "./multiple_choice.css"

import { MdOutlineDelete} from "react-icons/md"
import { useState } from "react"
import Blanck_input from "../input/blanck_input"
import MDButton from "../MDButton"





function Multiple_choice(props){


    const abcd = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]

    return(
        <div className="multiple-choice-box">
            <>
                {props.options.map((item)=>{
                    return (
                        <div className="option-box">
                            <div style={{color:"gray"}} className="index">{abcd[props.options.indexOf(item)]}</div>
                            <Blanck_input
                                sx = {{color:"white", textAlign:"left"}}
                                id = "blanck-input"
                                className = "medium"
                                value = {item}
                                onClick = {props.onClick}
                            />
                        </div>
                    )
                })}
            </>
        </div>
    )
}

export default Multiple_choice