//BIBLIOTECA PARA BOTAO COM LOADER SPIN
import { Button as SpinButton } from "rsuite"; 
import "rsuite/dist/rsuite.min.css"; // Default CSS 

import style from "./form.css"
import { MdDelete, MdLink} from "react-icons/md";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BackgroundLetterAvatars from "./avatar";

export default function Form(props){
    
    return(
        <div className="form-container">
            <div onClick={props.onClick} className="box1">
                <div>
                    <BackgroundLetterAvatars name = {props.name}/>
                </div>
                <div style={{marginInline:"10px"}}>
                    <MDTypography variant="h6" color="dark">{props.name}</MDTypography>
                </div>
            </div>
            <div className="box2">
               <MDTypography variant="h6" color="dark">{props.date}</MDTypography>
               <MDButton onClick={props.onClickResponse} style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginInline:"20px"}}>
                    <MDTypography sx={{marginRight:"5px"}} variant="h6" color="dark">Respostas</MDTypography>
                    <MDTypography variant="h6" color="dark">{props.response}</MDTypography>
               </MDButton>
               <MDButton onClick={props.onClickShare} title="copy link" variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                    <MdLink size={20}/>
               </MDButton>
               {!props.isLoading
                ?<MDButton title="delete" variant="gradient" bgColor="warning" borderRadius="lg" coloredShadow="warning">
                    <MdDelete onClick={props.onClickDelete} size={20}/>
                </MDButton>
                :<MDButton title="deleting" variant="gradient" bgColor="warning" borderRadius="lg" coloredShadow="warning">
                    <SpinButton loading size={20}/>
                </MDButton>
               }
               

            </div>
        </div>
    )
}