import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { MdLock } from "react-icons/md";


export default function Plataform(props){
    return(
        <div className="platafor-box">
            <MDTypography variant="h6" fontWeight="medium" color="black">{props.name}</MDTypography>
            <MDTypography variant="h6" fontSize="small" color="text">{props.description}</MDTypography>
            {!props.avaliable
                ?<div style={{marginBottom:"5px"}}>
                    <MDBox sx = {{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", width:"100%", height:"45px", border:"1px #d9d9d9 solid", borderRadius:"5px", padding:"8px 10px"}}>
                        <MDTypography variant="h6" color="text">For Pro/Business plans only</MDTypography>
                        <MDButton size="small" sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <MdLock size={20}/>
                            <MDTypography variant="h6" color="text">upgrad to unlock all features</MDTypography>
                        </MDButton>
                    </MDBox>
                </div>
                :<MDInput 
                    sx={{width:"100%"}}
                    type="text"
                    value = {props.value}
                    onChange = {props.onChange}
                    placeholder={props.placeholder}
                />
            }
            
        </div>
    )
}