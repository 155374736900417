//BIBLIOTECA PARA BOTAO COM LOADER SPIN
import { Button as SpinButton } from "rsuite"; 
import "rsuite/dist/rsuite.min.css"; // Default CSS 

import style from "./editor.css"
import { useContext, useEffect, useRef, useState } from "react";
import { MdSettings, MdSave, MdViewCompact } from "react-icons/md"

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


import PageLayout from "examples/LayoutContainers/PageLayout";
import Question from "../../components/Question";
import { ArrowBack } from "@mui/icons-material";
import New_questionAlert from "./components/alertas/new_question";
import EditorSelected from "./selected";
import EditorCreating from "./creating";
import Setting from "./components/settings/seting";
import { useNavigate } from "react-router-dom";
import Preview from "layouts/preview/preview";
import { DataContext } from "layouts/dataprovider/dataprovider";
import MDTypography from "components/MDTypography";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { Card } from "@mui/material";

function Editor(props) {
  const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env

  const [isLoading, setIsLoading] = useState(false) //controlador para mostrar efeito de loading no botao 

  /*CONFIGURACAO DE ESTILOS*/
  const [buttonColor, setButtonColor] = useState("#2979FF")
  const [buttonFontColor, setButtonFontColor] = useState("#ffffff")
  const [questionColor, setQuestionColor] = useState("#eceff1")
  const [descriptionColor, setDescriptionColor] = useState("#ffffff")
  const [responseColor, setResponseColor] = useState("#ffffff")
  const [bgColor, setBgColor] = useState("#132239")
  const [backgroundImage, setBackgroundImage] = useState("")
  const [logo, setLogo] = useState("")
  
  /*CONSTANTES DE CONFIG DE METRICAS*/
  const [facebookId, setFacebookId] = useState("")
  const [googleTag, setGoogleTag] = useState("")
  const [googleAnalytics, setGoogleAnalytics] = useState("")
  const [webhook_url, setWebhook_url] = useState("")
  const [redirect_url, setRedirect_url] = useState("")

  /*ELEMENTOS DE PERGUNTAS NA CRIACAO*/
  /*Para tdas perguntas*/
  const [field_type, setField_type] = useState("short_text")
  const [title, setTitle] = useState("")
  const [description, setDescription]= useState("")

  const [button_text, setButton_text] = useState("Button text")
  const [thankyou_button_text, setThankyou_button_text] = useState("Send Form")

  /*Para Multichoice*/
  const [options, setOptions] = useState([])
  const [img_options, setImg_options] = useState([])
  const [list_options, setList_options] = useState(["element 1", "element 2", "element 3"])
  /*Valores da logica (por implementar)*/
  const [open_logic, setOpen_logic] = useState(false);
  const [logics, setLogics] = useState([])
  const [unsaved_logics, setUnsaved_logics] = useState([])


  /*ELEMENTOS DE PERGUNTAS SELECIONADO PARA EDIAO*/
  /*Para tdas perguntas*/
  const [selected_field_type, setSelected_field_type] = useState("short_text")
  const [selected_title, setSelected_title] = useState("")
  const [selected_description, setSelected_description]= useState("")
  const [selected_button_text, setSelected_button_text] = useState("Button text")
  const [selected_thankyou_button_text, setSelected_thankyou_button_text] = useState("Send Form")
  /*Para Multichoice*/
  const [selected_options, setSelected_options] = useState([])
  /*Valores da logica (por implementar)*/
  const [selected_logics, setSelected_logics] = useState([])


  
  /*Modal de alertas*/
  const [open_quetion_alert, setOpen_quetion_alert] = useState(false)
  const handleOpen_open_question_alert = ()=>  setOpen_quetion_alert(true)
  const handleOpen_close_question_alert = ()=>  setOpen_quetion_alert(false)

  const handleOpen_logic  = ()=> setOpen_logic(true);
  const handleClose_logic = ()=> setOpen_logic(false);

  
  /*Lista de todas perguntas*/
  const [questions, setQuestions] = useState([])
  const [selected_quetion, setSelected_quetion] = useState("")
  const [selected_index, setSelected_index] = useState(0)
  const [form_title, setForm_title] = useState("")
  const [form_id, setForm_id] = useState("")

  //Dados do formulario pegados do backend (Armazenado na variavel compartilhada)
  const {formData, setForm_listData} = useContext(DataContext)

  //PEGANDO TOKEN GUARDADO NA LOCALSTORATE
  const [localToken, setLocalToken] = useState("")
  const navigate = useNavigate()

  useEffect(()=>{
    let tk = localStorage.getItem("formchelo004")
    if (typeof(tk) !== undefined){setLocalToken(tk)}
    if (tk == null){navigate("/sign-up")}
  }, [])

  const isFirstRender = useRef(true) //GARANTE QUE TUDO NO USEEFFECT RODE APENAS NA PRIMEIRA RENDERIZACAO
  useEffect(()=>{
    isFirstRender.current = false
    if ("FORMID" in formData){setForm_id(formData.FORMID)}
    if ("FORM_BACKGROUND_COLOR" in formData){setBgColor(formData.FORM_BACKGROUND_COLOR)}
    if ("FORM_BACKGROUND_IMG" in formData){setBackgroundImage(formData.FORM_BACKGROUND_IMG)}
    if ("FORM_BUTTON_COLOR" in formData){setButtonColor(formData.FORM_BUTTON_COLOR)}
    if ("FORM_BUTTON_FONT_COLOR" in formData){setButtonFontColor(formData.FORM_BUTTON_FONT_COLOR)}
    if ("FORM_QUESTION_COLOR" in formData){setQuestionColor(formData.FORM_QUESTION_COLOR)}
    if ("FORM_DESCRIPTION_COLOR" in formData){setDescriptionColor(formData.FORM_DESCRIPTION_COLOR)}
    if ("FORM_RESPONSE_COLOR" in formData){setResponseColor(formData.FORM_RESPONSE_COLOR)}
    if ("FORM_LOGO" in formData){setLogo(formData.FORM_LOGO)}
    if ("FORM_FACEBOOK_PIXEL" in formData){setFacebookId(formData.FORM_FACEBOOK_PIXEL)}
    if ("FORM_GOOGLE_TAG_MANAGER" in formData){setGoogleTag(formData.FORM_GOOGLE_TAG_MANAGER)}
    if ("FORM_GOOGLE_ANALYTICS" in formData){setGoogleAnalytics(formData.FORM_GOOGLE_ANALYTICS)}
    if ("WEBHOOK_URL" in formData){setWebhook_url(formData.WEBHOOK_URL)}
    if ("REDIRECT_URL" in formData){setRedirect_url(formData.REDIRECT_URL)}

    try {
      setForm_title(formData.FORM_NAME)
      let str = formData.QUESTION_FLOW
      str = str.replace(/'/g, '"');// Substituir apóstrofos simples por aspas duplas
      setQuestions(JSON.parse(str)); // Converter string em array de objetos
    } catch (error) {
      console.log(error);
    }

    return
  }, [])

  

  /*Constantes e funcoes para settings*/
  const [open_setting, setOpen_setting]= useState(false)
  const handleOpen_settting = ()=> setOpen_setting(true)
  const handleClose_settting = ()=> setOpen_setting(false)

  //CONSTANTES E FUNCOES PARA PREVIEW
  const [openPreview, setOpenPreview] = useState(false)
  const handleOPenPreview = ()=> setOpenPreview(true)
  const handleClosePreview = () => setOpenPreview(false)


  const close_selected = ()=> {
    setSelected_quetion("")
    setSelected_description("")
    setSelected_button_text("")
  }
  
  const handleSelected = (q)=>{
    setSelected_quetion(q);
    setSelected_index(questions.indexOf(q));
    setSelected_title(q.title);
    setSelected_description(q.description);
    setSelected_button_text(q.button_text);
    setSelected_thankyou_button_text(q.thankou_button_text);
    setSelected_options(q.options);
    setSelected_field_type(q.type);
    setSelected_logics(q.logic);
    console.log({selected_q:q});
  }

  const handleUpdateQuestion = (index)=>{
    const updatedQuestion = questions.map((q, i) => {
      if (i === index) {
        // Selected and updated question
        return {
          "type":selected_field_type,
          "title":selected_title,
          "description":selected_description,
          "button_text":selected_button_text,
          "thankou_button_text":selected_thankyou_button_text,
          "options":selected_options, 
          "logic":q.logic //mantem a logca e atualiza o resto  
        };
      } else {
        // The rest haven't changed
        return q;
      }
    });
    setQuestions(updatedQuestion);
    setUnsaved_logics([]);
    close_selected();
  }



  function append_question(){
    const input_type = ["name", "short_text", "long_text", "email", "phone", "date", "link", "number", "money", "address", "satisfation", "message", "welcome", "thankyou"]

    if(title.length<1){
      handleOpen_open_question_alert()
    } else{
      // Renove o thankou se ele ja estiver presente na lista de perguntas e // Adiciona o novo elemento à lista filtrada
      const filteredArray = questions.filter(element=> element.type !== "thankyou")
      const lastArray = questions.filter(element=> element.type == "thankyou")
      
      const lastElement = ()=>{
        if(lastArray.length>0){
          return lastArray[0]
        } else{
          return {
            "type":"thankyou",
            "title":"thankyou mensage",
            "description":"",
            "button_text":button_text,
            "thankou_button_text":thankyou_button_text,
            "options":[],
            "logic":[]
          }
        }
      }
      

      if (input_type.includes(field_type)){
        const newArray = [...filteredArray, {
          "type":field_type,
          "title":title,
          "description":description,
          "button_text":button_text,
          "thankou_button_text":thankyou_button_text,
          "options":[],
          "logic":logics
        }]
        newArray.push(lastElement())
        setQuestions(newArray)
      }
  
      if (["multiple_choice"].includes(field_type)){
        const newArray = [...filteredArray, {
          "type":field_type,
          "title":title,
          "description":description, 
          "options":options, 
          "logic":logics
        }]
        newArray.push(lastElement())
        setQuestions(newArray)
        setOptions([])
      }
      if (["image"].includes(field_type)){
        const newArray = [...filteredArray, {
          "type":field_type,
          "title":title,
          "description":description, 
          "options":img_options, 
          "logic":logics
        }]
        newArray.push(lastElement())
        setQuestions(newArray);
        setImg_options([])
      }
      if (["list"].includes(field_type)){
        const newArray = [...filteredArray, {
          "type":field_type,
          "title":title,
          "description":description, 
          "options":list_options, 
          "logic":logics
        }]
        newArray.push(lastElement())
        setQuestions(newArray);
      }
    }
    
    /*Resetando valores que ja foram submetidos*/
    setUnsaved_logics([]);
    setLogics([]);

    /*Headeres de todos campampos(tituloe descricao)*/
    setTitle("");
    setDescription("");
    setButton_text("Button Text")
    
    
    const titulo  = document.getElementById("titulo");
    const descricao  = document.getElementById("descricao");
    titulo.value = "";
    descricao.value = "";
    
    console.log(questions);
  };


  function handleSubmitForm(){

    const localToken =  localStorage.getItem("formchelo004")

    setIsLoading(true)
    if(form_id.length>0){
      //SE EXISTIR O ID DO FORMULARIO ENTAO ATUALIZA
      axios.post(`${apiUrl}/v1/admin/form/update`, {
        token:localToken,
        form_id:form_id,
        form:{
          name:form_title,
          logo:logo,
          question_flow:questions,
          background_color:bgColor,
          background_img:backgroundImage,
          button_color:buttonColor,
          button_font_color:buttonFontColor,
          question_color:questionColor,
          description_color:descriptionColor,
          response_color:responseColor,
          facebook_id:facebookId,
          google_tag:googleTag,
          google_analytics:googleAnalytics,
          webhook_url:webhook_url,
          redirect_url:redirect_url
        }}).then((res)=>{
          if(res.data.estado){
            setForm_listData(res.data.response)
          }
          setIsLoading(false)
          navigate("/forms")
        })
    } else{
        //SE NAO, CRIA NOVO FORMULARIO
        axios.post(`${apiUrl}/v1/admin/form/create`,{
          token:localToken,
          form:{
            name:form_title,
            logo:logo,
            question_flow:questions,
            background_color:bgColor,
            background_img:backgroundImage,
            button_color:buttonColor,
            button_font_color:buttonFontColor,
            question_color:questionColor,
            description_color:descriptionColor,
            response_color:responseColor,
            facebook_id:facebookId,
            google_tag:googleTag,
            google_analytics:googleAnalytics,
            webhook_url:webhook_url,
            redirect_url:redirect_url
          }})
          .then((res)=>{
            if(res.data.estado){
              setForm_listData(res.data.response)
            }
            setIsLoading(false)
            navigate("/forms")
          })
    }
    
  }
  

  return (
    <PageLayout>
      <Setting 
        open = {open_setting}
        toClose ={handleClose_settting}
        buttonColor = {buttonColor} 
        setButtonColor = {setButtonColor}
        buttonFontColor = {buttonFontColor}
        setButtonFontColor = {setButtonFontColor}
        questionColor = {questionColor}
        setQuestionColor = {setQuestionColor}
        descriptionColor = {descriptionColor}
        setDescriptionColor = {setDescriptionColor}
        responseColor = {responseColor}
        setResponseColor = {setResponseColor}
        bgColor = {bgColor}
        setBgColor = {setBgColor}
        backgroundImage = {backgroundImage}
        setBackgroundImage = {setBackgroundImage}
        logo = {logo}
        setLogo = {setLogo}
        form_name = {form_title}
        setForm_name = {setForm_title}

        facebookId = {facebookId}
        setFacebookId = {setFacebookId}
        googleTag = {googleTag}
        setGoogleTag = {setGoogleTag}
        googleAnalytics = {googleAnalytics}
        setGoogleAnalytics = {setGoogleAnalytics}
        webhook_url = {webhook_url}
        setWebhook_url = {setWebhook_url}
        redirect_url = {redirect_url}
        setRedirect_url = {setRedirect_url}
      />
      <Preview
        open={openPreview}
        questions={questions}
        handleClose = {handleClosePreview}
        buttonColor = {buttonColor} 
        setButtonColor = {setButtonColor}
        buttonFontColor = {buttonFontColor}
        setButtonFontColor = {setButtonFontColor}
        questionColor = {questionColor}
        setQuestionColor = {setQuestionColor}
        descriptionColor = {descriptionColor}
        setDescriptionColor = {setDescriptionColor}
        responseColor = {responseColor}
        setResponseColor = {setResponseColor}
        bgColor = {bgColor}
        setBgColor = {setBgColor}
        backgroundImage = {backgroundImage}
        setBackgroundImage = {setBackgroundImage}
        redirect_url = {redirect_url}
        logo = {logo}
        setLogo = {setLogo}
      />
      <New_questionAlert open={open_quetion_alert} close = {handleOpen_close_question_alert}/>
      <MDBox className = "editor-container" >
        <MDBox className = "editor-nav-container" >
          <div className="back">
            <div title="back"><ArrowBack onClick={()=>{
              setForm_id("") //AO SAIR DO EDITOR, RESETA O ID DO FORMULARIO PARA ZERO
              navigate("/forms")}}
            /></div>
            <div><MDTypography variant="h6" color="dark">{form_title}</MDTypography></div>
          </div>
          <div style={{display:"flex"}}>
            {questions.length>0
              ?<>
                <MDButton color = "dark" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content", marginRight:"5px"}}>
                <div><MdSettings onClick={handleOpen_settting} size={20}/></div>
              </MDButton>
                <MDButton color = "black" size = "small" variant = "outlined" sx={{marginRight:"5px"}}>
                  <div><FaEye onClick={handleOPenPreview} size={20}/></div>
                </MDButton>
                <MDButton color = "dark" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content"}}>
                  <div>
                    {!isLoading
                      ?<MdSave onClick={handleSubmitForm} size={20}/>
                      :<SpinButton loading color="white" size={40}/>
                    }
                    
                  </div>
                </MDButton>
              </>
              :<></>
            }
            
          </div>
        </MDBox>
        <MDBox sx = {{display: "flex", flexDirection: "row", height: "100%"}}>
          {questions.length>0 &&
            <div style = {{backgroundColor:"rgba(231, 231, 231, 0.747)", width: "50%", marginRight: "10px", maxWidth: "400px", height: "100%", maxHeight:"93vh", displa:"flex", flexDirection:"column"}}>
              <div className = "questions">
                {questions.map((q)=>{
                  return(
                    <>
                      <Question
                        onClick = {()=>{handleSelected(q)}}
                        onClick_delete = {()=>{
                          setQuestions(questions.filter(op=> op !== q))
                        }}
                        type = {q.type}
                        title = {q.title}
                        question_list = {questions}
                        object = {q}
                      />
                    </>
                  )
                })}
              </div>
            </div>

          }
          {selected_quetion.length<1
            ?<EditorCreating
              field_type = {field_type}
              setField_type = {setField_type}
              title = {title}
              onChange_title={e=>setTitle(e.target.value)}
              description={description}
              onChange_description={e=>setDescription(e.target.value)}
              buttonTextValue={button_text}
              onChange_button_value={e=>setButton_text(e.target.value)}
              thankou_btn_text_value = {thankyou_button_text} 
              onChange_thankou_btn_text = {e=> setThankyou_button_text(e.target.value)}
              
              options={options}
              setOptions={setOptions}
              img_options={img_options}
              setImg_options={setImg_options}
              list_options={list_options}
              setList_options={setList_options}
              
              handleOpen_logic={handleOpen_logic}
              append_question = {append_question}

              unsaved_logics = {unsaved_logics}
              setUnsaved_logics = {setUnsaved_logics}
              setLogics={setLogics}
              questions={questions}
              open_logic={open_logic}
              handleClose_logic={handleClose_logic}
            />
            :<EditorSelected
              question = {selected_quetion}
              field_type={selected_field_type}
              setField_type = {setSelected_field_type}
              title={selected_title}
              onChange_title={e=>setSelected_title(e.target.value)}
              description={selected_description}
              onChange_description={e=>setSelected_description(e.target.value)}
              buttonTextValue={selected_button_text}
              onChange_button_value={e=>setSelected_button_text(e.target.value)}
              thankou_btn_text_value = {selected_thankyou_button_text}
              onChange_thankou_btn_text = {e=> setSelected_thankyou_button_text(e.target.value)}
  
              options={selected_options}
              setOptions={setSelected_options}
              
              handleOpen_logic={handleOpen_logic}
              onClick_update = {()=>{handleUpdateQuestion(selected_index)}}
              close_selected = {close_selected}

              unsaved_logics = {selected_logics}
              setUnsaved_logics = {setSelected_logics}
              qIndex = {selected_index}
              questions={questions}
              setQuestions={setQuestions}
              open_logic={open_logic}
              handleClose_logic={handleClose_logic}
            /> 
          }
          
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

export default Editor;
