import style from "./metricas.css"

import MDTypography from "components/MDTypography";
import { useOutletContext } from "react-router-dom";
import Plataform from "./plataform";
import { useContext, useEffect } from "react";
import { DataContext } from "layouts/dataprovider/dataprovider";

export default function Metricas(){
    const {permitionData} = useContext(DataContext)

    const [
        buttonColor, 
        setButtonColor,
        buttonFontColor,
        setButtonFontColor,
        questionColor,
        setQuestionColor,
        descriptionColor,
        setDescriptionColor,
        responseColor,
        setResponseColor,
        bgColor,
        setBgColor,
        backgroundImage,
        setBackgroundImage,
        logo,
        setLogo,
        form_name,
        setForm_name,
        facebookId, setFacebookId,
        googleTag, setGoogleTag,
        googleAnalytics, setGoogleAnalytics,
        webhook_url, setWebhook_url,
        redirect_url, setRedirect_url
    ] = useOutletContext()
    
    const facebook_state = ()=>{
        return permitionData.includes("Facebook_Pixel")
    }

    const Google_analytics_state = ()=>{
        return permitionData.includes("Google_Analytics")
    }

    const Google_tag_manager_state = ()=>{
        return permitionData.includes("Google_Tag_Manager")
    }
    
    return(
        <div className="metrica-box">
            <div className="content">
                <div className="box">
                    <div className="header">
                        <MDTypography variant="h4" fontWeight="medium" color="black">Metrics and Conversions</MDTypography>
                        <MDTypography variant="h6" fontSize="small" color="text">Events are always sent to these integrations, regardless of whether they are complete or incomplete.</MDTypography>
                    </div>
                    <Plataform
                        name = "Facebook"
                        avaliable = {facebook_state()}
                        description = "Add your Facebook Pixel ID"
                        value = {facebookId}
                        onChange = {e=>setFacebookId(e.target.value)}
                    />
                    <Plataform
                        name = "Google Analytics"
                        avaliable = {Google_analytics_state()}
                        description = "Add your Google Analytics Tracking ID"
                        value = {googleAnalytics}
                        onChange = {e=>setGoogleAnalytics(e.target.value)}
                    />
                    <Plataform
                        name = "Google Tag Manager"
                        avaliable = {Google_analytics_state()}
                        description = "Add your GTM Container ID"
                        value = {googleTag}
                        onChange = {e=>setGoogleTag(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}