
import style from "./input.css"

const Input_text = (props)=>{
    return(
        <div className="input-question">
            <div>
                <input id="response" onChange={props.onChange} type="text" placeholder={props.placeholder} >{props.text}</input>
                <hr />
            </div>
            <button style={props.btn_style} id="btn" onClick = {props.onClick}>
                {props.button_text}
            </button>
        </div>
    )
}



export default Input_text