

import style from "./alert.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useState } from "react";
import {FaExclamationTriangle} from "react-icons/fa"
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import MDInput from "components/MDInput";
import { BorderColor } from "@mui/icons-material";
import { MdLock, MdNote } from "react-icons/md";
import { Typography } from "@mui/material";

const styles = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    minHeight:"400px",
    bgcolor: 'background.paper',
    borde:'none',
    borderRadius:'10px',
    boxShadow: 24,
    p: 4,
};



export default function Upgrade_to_unlock_response(props){

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className='alert-box'>
            <div>
              <MdLock size={40}/>
            </div>
            <div className="text">
                <MDTypography color = "dark" variant="h4" gutterBottom>Response Limit Reached</MDTypography>
                <MDTypography color = "dark" variant="h6" gutterBottom>
                  You have reached the maximum number of responses allowed for your current plan. Upgrade to the next plan to access the responses received.
                </MDTypography>
            </div>
            <div className="btn">
              <MDButton onClick={props.onClick} color = "info" sx={{width:"100%"}}>Upgrade Now</MDButton>
            </div>
            <Typography variant="h6" fontSize="small" color="grey" onClick={props.close}>not now</Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
