
import style from "./alert.css"
import { Box, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";
import Lottie from "lottie-react";
import sad_email_animation from "./saad_email_animation.json"
import MDButton from "components/MDButton";


const styles = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    minHeight:"400px",
    bgcolor: 'background.paper',
    borde:'none',
    borderRadius:'10px',
    boxShadow: 50,
    p: 4,
};



export default function Unverified_email(props){

  const opencEmailClient = ()=>{
    window.location.href = 'mailto:';
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className='alert-box'>
            <div className="icon">
                <Lottie animationData={sad_email_animation} style={{ height: 180 }}/>
            </div>
            <div className="text">
                <MDTypography color = "dark" variant="h4" gutterBottom>Verify Your Email</MDTypography>
            </div>
            
            <MDTypography variant="h6" color="grey" onClick={props.close}>
              To start using FormChelo, please check your email and click the verification link we sent you.
            </MDTypography>
            <MDButton onClick={opencEmailClient} fullWidth variant="gradient" color="info">
              Open Your Inbox
            </MDButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
