import style from "./satisfation.css"

function Satisfation_rate(){

    const Option = (props) =>{
        return <input id = "satisfation-option" type = "button" value = {props.value}/>
    }
    
    return (
        <div className="satisfation-box">
            <Option value ="0"/>
            <Option value ="1"/>
            <Option value ="2"/>
            <Option value ="3"/>
            <Option value ="4"/>
            <Option value ="5"/>
            <Option value ="6"/>
            <Option value ="7"/>
            <Option value ="8"/>
            <Option value ="9"/>
            <Option value ="10"/>
        </div>
    )
}
export default Satisfation_rate