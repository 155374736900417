import { MdArrowForward } from "react-icons/md"

const { default: MDButton } = require("components/MDButton")


const Mensage = ()=>{
    return(
        <>
            <MDButton 
                color = "dark" size = "small" variant = "outlined" sx ={{display:"flex", width:"100px", alignItems:"center", justifyContent:"center", height:"min-content", marginTop:"25px"}}
            >
                Next
                <MdArrowForward/>
            </MDButton>
        </>
    ) 
    
    
}

export default Mensage