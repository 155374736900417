import imagem from "assets/images/noform.png"
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";



export default function NOForm(props){
    return(
        <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center", width:"700px", margin:"30px auto"}}>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <img src = {imagem} style={{width:"250px"}}/>
            </MDBox>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <MDBox>
                    <MDTypography variant="h5" color="dark" sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                        You don't have any forms yet.
                    </MDTypography>
                    <MDTypography variant="h6" color="dark" sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                        Create your first form now to start collecting responses and gaining valuable insights. 
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <MDButton onClick = {props.onClick} variant="gradient" color="info">
                    Create now 
                </MDButton>
            </MDBox>
        </MDBox>
    )
}