


import style from "./alert.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useContext, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import MDInput from "components/MDInput";
import { Typography } from "@mui/material";
import { MdContentCopy, MdLink } from "react-icons/md";
import { DataContext } from "layouts/dataprovider/dataprovider";
import BackgroundLetterAvatars from "layouts/forms/avatar";

import { ToastContainer, toast } from 'react-toastify'; //Biblioteca para notificaoes estilozas
import MDBox from "components/MDBox";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";

const styles = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    minHeight:"400px",
    bgcolor: 'background.paper',
    borde:'none',
    borderRadius:'10px',
    boxShadow: 24,
    p: 4,
};



export default function ShareForm(props){
  const {formData} = useContext(DataContext)
  const inputRef = useRef(null);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(formData.FORM_URL);
      props.close()
      //toast.success('Texto copiado: ' + formData.FORM_URL); utilizado para mostrar notificacao bonita
    } catch (err) {
      console.error('Falha ao copiar: ', err);
    }
  };

  const copyEmbedCode = async () => {
    try {
      await navigator.clipboard.writeText(
        `
        <iframe src=${formData.FORM_URL} width="100%" height="100%" style="border:none; margin: 0px;">
        </iframe>
        `
      );
      props.close()
      //toast.success('Texto copiado: ' + formData.FORM_URL); utilizado para mostrar notificacao bonita
    } catch (err) {
      console.error('Falha ao copiar: ', err);
    }
  };

  const shareOnfacebook = ()=>{
    window.location.assign(`https://www.facebook.com/sharer/sharer.php?u=${formData.FORM_URL}`)
  }

  const shareOnTwitter = ()=>{
    window.location.assign(`https://twitter.com/intent/tweet?text=${formData.FORM_URL}`)
  }
  const shareOnLinkedin = ()=>{
    window.location.assign(`https://www.linkedin.com/shareArticle/?url=${formData.FORM_URL}`)
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <ToastContainer/>
          <div className='alert-box'>
            <div className="icon"><BackgroundLetterAvatars name = {formData.FORM_NAME}/></div>
            <div className="text">
                <MDTypography color = "dark" variant="h5" gutterBottom>{formData.FORM_NAME}</MDTypography>
            </div>
            <MDBox sx ={{width:"100%"}}>
              <MDTypography variant="h5" fontSize="small" color="grey" sx={{textAlign:"left"}}>Send this link by email, or share it on your social media.</MDTypography>
              <MDInput
                ref={inputRef}  
                value = {formData.FORM_URL}
                placeholder = "your new form here..."
                sx = {{BorderColor:"blue", width:"100%"}}
              />
            </MDBox>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"40%"}}>
              <FaFacebook onClick={shareOnfacebook} title="share on Facebook"/>
              <FaTwitter onClick={shareOnTwitter} title="share on Twitter"/>
              <FaLinkedin onClick={shareOnLinkedin} title="share on Linkedin"/>
              <MdContentCopy onClick={copyToClipboard} title="copy link"/>
            </MDBox>
            <div className="btn">
              <MDTypography variant="h5" fontSize="small" color="grey" sx={{textAlign:"left"}}>Add the form on your website</MDTypography>
              <MDButton onClick = {copyEmbedCode} color = "info" sx={{width:"100%"}}>
                Copy enbed code
              </MDButton>
            </div>
            <Typography variant="h5" fontSize="small" color="grey" onClick={props.close}>close</Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
