import style from "./editor.css"
import { useState } from "react";

import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDDropdown from "components/MDDropdown"
import Question from "components/Question"
import Question_bulilder from "components/Question_builder"
import {MdCallSplit, MdCancel, MdUpdate } from "react-icons/md"
import Logic_update from "./components/Logica/logic_update";


const EditorSelected = (props)=>{
    
    const input_type = ["name", "short_text", "long_text", "email", "phone", "date", "link", "number", "money", "address", "satisfation", "message", "welcome", "thankyou"]
    

    return(
        <Card sx = {{width: "70%", margin: "50px auto"}}>
            <Logic_update
                unsaved_logics={props.unsaved_logics}
                setUnsaved_logics={props.setUnsaved_logics}
                qIndex = {props.qIndex}
                questions = {props.questions}
                setQuestions = {props.setQuestions}
                open = {props.open_logic}
                handleClose = {props.handleClose_logic}
            />
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className = "content-nav"
            >
                <input style={{width:"300px", height:"40px", marginLeft:"25px", paddingLeft:"10px", fontWeight:"bolder", fontSize:"medium"}} 
                    disabled  value = {props.field_type}
                    onChange = {(e)=>{props.setField_type(e.target.value)}
                }/>
                <div>
                    <MDButton onClick = {props.handleOpen_logic} color = "white" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content", marginRight:"20px"}}>
                        <div><MdCallSplit size={20}/></div>
                        <div>Add Logic</div>
                    </MDButton> 
                    <MDButton onClick = {props.onClick_update} color = "white" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content", marginRight:"20px"}}>
                        <div><MdUpdate size={20}/></div>
                        <div>Save Update</div>
                    </MDButton>
                    <MDButton onClick = {props.close_selected} color = "white" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content"}}>
                        <div><MdCancel size={20}/></div>
                        <div>Cancel Update</div>
                    </MDButton>
                </div>
            </MDBox>
            <div className = "content-box">
                {/*Como podemos selecionar apenas uma pergunta de cada vez, entao todas options(options, imgs, list) irao receber a mesma props de options*/}
                <Question_bulilder 
                    titleValue={props.title}
                    descriptionValue={props.description}
                    onChange_title = {props.onChange_title}
                    onChange_description = {props.onChange_description}
                    buttonTextValue = {props.buttonTextValue}
                    onChange_button_value = {props.onChange_button_value}
                    options = {props.options}
                    setOptions = {props.setOptions}
                    img_options = {props.options}
                    setImg_options = {props.setOptions}
                    list_options = {props.options}
                    setList_options = {props.setOptions}
                    thankou_btn_text_value = {props.thankou_btn_text_value}
                    onChange_thankou_btn_text = {props.onChange_thankou_btn_text}
                    type = {props.field_type}
                />
            </div>
        </Card>
    )
}

export default EditorSelected