import style from "./list_selector.css"
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MDButton from 'components/MDButton';

import { useState } from "react"

function List_selector(props) {
    const [text, setText] = useState("");

    const handleChange = (e) => {
        setText(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newLines = text.split('\n');
        props.setList_options(newLines);
    };


    return (
        <div className="list-selector-box">
            <div className="options">
                {props.list_options.map((item)=>{
                    return (
                        <FormGroup>
                            <FormControlLabel  control={<Checkbox />} label={item} />
                        </FormGroup>
                    )
                })}
            </div>
            <div className="add-new">
                <textarea
                    value={text}
                    onChange={handleChange}
                    placeholder="Enter text here..."
                />
                <div>
                    <MDButton
                        onClick={handleSubmit}
                        color="dark"
                        size ="small"
                    >Update</MDButton>
                </div>
            </div>
        </div>
    );
}


export default  List_selector