import MDInput from "components/MDInput";
import Image_selector from "./components/image_selector/image_selctor";
import Blanck_input from "./components/input/blanck_input";
import Underline_input from "./components/input/underline_input";
import List_selector from "./components/List_selector/list_selector";
import Mensage from "./components/mensage/mensage";
import Multiple_choice from "./components/Multiple_choice/multiple_choice";
import Satisfation_rate from "./components/satisfation_rate/satisfation_rate";
import ThankYou from "./components/Thankyou/thankyou";



function Question_bulilder(props){

    const input_fields = ["name", "short_text", "long_text", "email", "phone", "date", "link", "number", "money", "address"]

    return(
        <>
            {props.type!=="thankyou" &&
                <>
                    <Blanck_input
                        id = "titulo"
                        className = "titulo"
                        value = {props.titleValue}
                        placeholder = "Type your question here..."
                        onChange = {props.onChange_title}
                    />
                    <Blanck_input
                        id = "descricao"
                        className = "descricao"
                        value = {props.descriptionValue}
                        placeholder = "Describe your question here..."
                        onChange = {props.onChange_description}
                    />
                </>
            }
            {input_fields.includes(props.type)
                ?<div>
                    <Underline_input
                        className = "medium"
                    />
                    <MDInput
                        value = {props.buttonTextValue}
                        onChange = {props.onChange_button_value}
                        sx={{
                            fontWeight:"bolder",
                            fontSize:"larger"
                        }}
                    />
                </div>
                :<></>
            }
            {props.type == "message" &&
                <div>
                    <MDInput
                        value = {props.buttonTextValue}
                        onChange = {props.onChange_button_value}
                        sx={{
                            fontWeight:"bolder",
                            fontSize:"larger",
                            marginTop:"20px"
                        }}
                    />
                </div>
            }
            {props.type == "welcome"  &&
                <div>
                    <MDInput
                        value = {props.buttonTextValue}
                        onChange = {props.onChange_button_value}
                        sx={{
                            fontWeight:"bolder",
                            fontSize:"larger",
                            marginTop:"20px"
                        }}
                    />
                </div>
            }
            {props.type == "multiple_choice" &&
                <div>
                    <Multiple_choice
                        options = {props.options}
                        setOptions = {props.setOptions}
                    />
                </div>
            }
            {props.type == "image" &&
                <div>
                    <Image_selector
                        options = {props.img_options}
                        setOptions = {props.setImg_options}
                    />
                </div>
            }
            {props.type == "list" &&
                <div>
                    <List_selector
                        list_options = {props.list_options}
                        setList_options = {props.setList_options}
                    />
                </div>
            }
            {props.type == "satisfation" &&
                <div>
                    <Satisfation_rate
                    />
                </div>
            }
            {props.type == "thankyou"  &&
                <div>
                    <ThankYou
                        titleValue ={props.titleValue}
                        descriptionValue = {props.descriptionValue}
                        onChange_title = {props.onChange_title}
                        onChange_description = {props.onChange_description}
                        thankou_btn_text_value = {props.thankou_btn_text_value}
                        onChange_thankou_btn_text = {props.onChange_thankou_btn_text}
                    />
                    
                </div>
            }
        </>
    );
}


export default Question_bulilder