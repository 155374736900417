

import style from "./alert.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useState } from "react";
import {FaExclamationTriangle} from "react-icons/fa"
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "500px",
  minHeight:"400px",
  bgcolor: 'background.paper',
  borde:'none',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4
};



function New_questionAlert(props){

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className='alert-box'>
            <div className="icon"><SentimentDissatisfiedOutlinedIcon sx={{width:"100px", height:"100px"}}/></div>
            <div className="text">
                <MDTypography color = "error" variant="h4" gutterBottom>Failed to Add Question</MDTypography>
                <MDTypography color = "text" variant="p">
                  To add a question to your form, first fill in the question field, then click "Add Question".
                </MDTypography>
            </div>
            <div className="btn">
              <MDButton onClick={props.close} color = "info" sx={{width:"80%"}}>OK</MDButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default  New_questionAlert