import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import style from "./style.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};


export default function MDDropdown(props) {

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300}}>
        <Select
          sx={{height:"40px"}} 
          value={props.value}
          displayEmpty
          onChange={props.onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="short_text">Short Answer</MenuItem>
          <MenuItem value="long_text">Long Text</MenuItem>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="email">E-mail</MenuItem>
          <MenuItem value="phone">Phone Number</MenuItem>
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="link">Link</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="money">Monetary Value</MenuItem>
          <MenuItem value="address">Address</MenuItem>
          <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
          <MenuItem value="image">Image Selection</MenuItem>
          <MenuItem value="list">List Selection</MenuItem>
          <MenuItem value="satisfation">Satisfaction Scale</MenuItem>
          <MenuItem value="message">Message</MenuItem>
          <MenuItem value="welcome">Welcome</MenuItem>
          <MenuItem value="thankyou">Thank You</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
