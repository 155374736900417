import { Height } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Lottie from "lottie-react";
import animationData from "./email_sent_animation.json"
import email_verified_animation2 from "./email_verified2.json"
import sad_email_animation from "./saad_email_animation.json"

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "layouts/dataprovider/dataprovider";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";


export function VerifyEmailSent(){
    const {authData} = useContext(DataContext)

    const opencEmailClient = ()=>{
        window.location.href = 'mailto:';
    }

    const resentEmail = ()=>{
        console.log(authData)
    }

    return(
        <PageLayout sx={{display:"flex"}}>
            <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"center", AjustifyItems:"center", AlignContent:"center",  maxWidth:"950px", height:"max-content", margin:"auto"}}>
                <MDBox>
                    <Lottie animationData={animationData} style={{ height: 485 }}/>
                </MDBox>
                <MDBox textAlign="center">
                    <MDTypography variant="h2" fontWeight="medium" color="info" mt={1}>
                        Verify your email address
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" color="primery" mt={1}>
                        We have sent a verification link to you. 
                        <br/>
                        <br/>
                        Click on the link to complete the verification process <br/>
                        You might need to <strong>check your spam folder.</strong>
                    </MDTypography>
                </MDBox>
            </MDBox>
        </PageLayout>
    )
}

export function VerifyEmailProcess(){
    const params = new URLSearchParams(window.location.search);
    const user_id = params.get('u');
    const api_url = process.env.REACT_APP_API_URL //URL do server pegado da .env

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(()=>{
        setIsLoading(true)
        axios.get(`${api_url}/v1/user/auth/verify/credential/${user_id}`)
        .then(res=>{
            if(res.data.estado){
                navigate("/email-verified")
            } else{navigate("/email-verification-expired")}
            setIsLoading(false)
        })
        .catch(error=>console.log(error))
    },[])

    return(
        <PageLayout>
            {isLoading
                ?<div style={{height:"80vh", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    <TailSpin color="#49a3f1"/>
                </div>
                :<></>
            }
        </PageLayout>
    )
}

export function EmailVerified(){

    const navigate =useNavigate()

    return(
        <PageLayout sx={{display:"flex"}}>
            <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"center", AjustifyItems:"center", AlignContent:"center",  maxWidth:"950px", height:"max-content", margin:"auto"}}>
                <MDBox>
                    <Lottie animationData={email_verified_animation2} style={{ height: 485 }}/>
                </MDBox>
                <MDBox textAlign="center">
                    <MDTypography variant="h2" fontWeight="medium" color="info" mt={1}>
                        Email Verified
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" color="primery" mt={1}>
                        Your email address was successfully verified.
                    </MDTypography>
                    <MDButton onClick={()=>navigate("/sign-in")} variant="gradient" color="info" sx={{marginTop:"25px", paddingInline:"80px"}}>Sign in Now</MDButton>
                </MDBox>
            </MDBox>
        </PageLayout>
    )
}

export function EmailVerifieLinkExpired(){

    const navigate =useNavigate()

    return(
        <PageLayout sx={{display:"flex"}}>
            <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"center", AjustifyItems:"center", AlignContent:"center",  maxWidth:"950px", height:"max-content", margin:"auto"}}>
                <MDBox>
                    <Lottie animationData={sad_email_animation} style={{ height: 485 }}/>
                </MDBox>
                <MDBox textAlign="center">
                    <MDTypography variant="h2" fontWeight="medium" color="info" mt={1}>
                        Expired link
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" color="primery" mt={1}>
                        The email verification link has expired.
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" color="primery" mt={1}>
                        To receive help, send an email to support@formchelo.com
                    </MDTypography>
                    
                </MDBox>
            </MDBox>
        </PageLayout>
    )
}