// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Form from "./form";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "layouts/dataprovider/dataprovider";
import axios from "axios";
import MDButton from "components/MDButton";
import { FaPlusSquare } from "react-icons/fa";
import NewQuestion from "./components/newQuestions/newquestion";
import Responses from "./components/responses/response";
import ShareForm from "./components/shareForm/share_form";
import NOForm from "components/NOForm/noform";
import { TailSpin } from "react-loader-spinner";

export function Forms(){
  const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env
  const [isLoading, setIsLoading] = useState(false) //controlador para mostrar efeito de loading no botao 
  let navigate = useNavigate()

  const {setFormData, form_listData, setForm_listData, setPermitionData} = useContext(DataContext) //valores do formulario selecionado
  const [newFormName, setNewFormName] = useState("")

  const [openNewQuestion, setOpenNewQuestion] = useState(false)
  const handleOpen = ()=> setOpenNewQuestion(true)
  const handleClose= ()=> setOpenNewQuestion(false)

  const [openResponses, setOpenResponses] = useState(false)
  const handleCloseResponse = ()=> setOpenResponses(false)

  const [openShare, setOpenShare] = useState(false)
  const handleCloseShare = ()=> setOpenShare(false)
  const handleOpenShare = (data)=> {
    setFormData(data)
    setOpenShare(true)
  }

  //VARIAVEL PARA APENAS UTIL COMO REFERENCIA PARA O USEeFFECT QUANDO UM FORMULARIO FOR ELIMINADO
  const [deleteChange, setDeleteChange] = useState("")

  
  //FUNCAO PARA REDIRECIONAR PARA O SIGN CASO O USUARIO NAO ESTEJA AUTENTICADO
  const isFirstRender = useRef(true)
  useEffect(()=>{
    const localToken = localStorage.getItem("formchelo004")
    if (localToken == null){navigate("/sign-up")}
    else{
      if(form_listData.length < 1 || form_listData.length === undefined){
        setIsLoading(true)
        axios.post(`${apiUrl}/v1/admin/form/get-all`, {
          token: localToken
        })
        .then((result)=>{
          setIsLoading(false)
          if(result.data.estado){
            setForm_listData(result.data.response)
            setPermitionData(result.data.permitions)
          }
        })
      }
      
    }
  },[deleteChange])

  
  //FUNCAO QUE ABRE O EDITOR COM A PERGUNTA SELECIONADA
  function handleNavigate(data){
    setFormData(data)
    navigate("editor")
    //console.log(data.FORM_NAME)
  }

  function handleOpenResponses(data){
    setFormData(data);
    setOpenResponses(true);
  }

  
  //FUNCA QUE DELETA UM FORMULARIO
  function handleDelete(id, item){
    setIsLoading(true)
    const localToken = localStorage.getItem("formchelo004")
    axios.post(`${apiUrl}/v1/admin/form/delete`, {
      form_id:id,
      token: localToken
    }).then(()=>{
      setIsLoading(false)
      setForm_listData(form_listData.filter(op=> op !== item))
    }
    )
  }
  
  return (
    <MDBox pt={6} pb={3}>
        <Responses
          open={openResponses}
          onClickClose = {handleCloseResponse}
        />
        <NewQuestion 
          open={openNewQuestion} 
          onClose = {()=>""}
          close = {()=>handleClose()}
          onChange= {e=> setNewFormName(e.target.value)}
          onClick = {()=>{
            setFormData({FORM_NAME: newFormName});
            handleClose();
            navigate("editor");
          }}
        />
        <ShareForm 
          open = {openShare}
          close = {()=>handleCloseShare()}
        />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}
              >
                <MDTypography variant="h6" color="white">
                  Your Forms
                </MDTypography>
                <MDButton onClick={handleOpen} sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                  <FaPlusSquare style={{marginRight:"5px"}}/>
                  New Form
                </MDButton>
              </MDBox>
              <MDBox pt={3} sx={{minHeight:"55vh", maxHeight:"70vh", display:"flex", flexDirection:"column", overflow:"auto"}}>
                {isLoading
                  ?<div style={{height:"80vh", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    <TailSpin color="#49a3f1"/>
                  </div>
                  :(form_listData.length>0
                    ?<>
                      {form_listData.map((f)=>{
                        return (
                          <Form 
                            name={f.FORM_NAME}
                            date={f.CREATED_DATE}
                            response = {f.FORM_SUBMITED_RESPONSE}
                            isLoading = {isLoading}
                            onClick={()=>handleNavigate(f)}
                            onClickShare = {()=> handleOpenShare(f)}
                            onClickResponse={()=>handleOpenResponses(f)}
                            onClickDelete={()=>handleDelete(f.FORMID, f)}
                          />
                        )
                      })}
                    </>
                    :<NOForm onClick={handleOpen}/>
                    )
                }
                
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  );
}

export function FormsLayout() {
  
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Forms/>
    </DashboardLayout>
  );
}
