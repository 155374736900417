import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import { useContext, useEffect, useState } from "react";
import {  MdPercent, MdUpload } from "react-icons/md";
import { FaEye, FaFile } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {Forms} from "layouts/forms";
import { TailSpin } from "react-loader-spinner";
import Unverified_email from "./components/unverified_email";
import { DataContext } from "layouts/dataprovider/dataprovider";

function Dashboard() {

  const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env

  const [isLoading, setIsLoading] = useState(false)

  const [forms, setForms] = useState(0)
  const [view, setView] = useState(0)
  const [submited, setSubmited] = useState(0)
  const [convertion, setConvertion] = useState(0)
  const [last_7_days, setLast_7_days] = useState({})
  const [last_3_month_submissions, setLast_3_month_submissions] = useState({})
  const [verified_account, setVerified_account] = useState(true)
  const [no_form, setNo_form] = useState(false)

  const {setPermitionData, setForm_listData} = useContext(DataContext)

  const navigate = useNavigate()


  useEffect(()=>{
    let tk = localStorage.getItem("formchelo004")
    if (tk == null){navigate("/sign-up")}
    else{
      axios.post(`${apiUrl}/v1/admin/dashboard`, {
      "token": tk
      })
      .then((res)=>{
        setIsLoading(false)
        if(res.data.estado){
          if(res.data.response.total_created_form == "0"){setNo_form(true)}
          setForms(res.data.response.total_created_form)
          setView(res.data.response.totla_view)
          setSubmited(res.data.response.total_submited_response)
          setConvertion(res.data.response.convertion_rate)
          setLast_7_days(res.data.response.last_7_day_submition)
          setLast_3_month_submissions(res.data.response.last_3_month_submissions)
          setVerified_account(res.data.response.account_verified)
          setPermitionData(res.data.response.tool)
          setForm_listData(res.data.response.forms)
        } else{console.log(res.data)}
      }).catch((error)=>{console.log(error)})
    }
    setIsLoading(true)
    
  },[])

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      {isLoading
        ?<div style={{height:"80vh", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
          <TailSpin color="#49a3f1"/>
        </div>
        :<MDBox py={3}>
          <Unverified_email
            open={!verified_account}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon={<FaFile/>}
                  title="Created Forms"
                  count={forms}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon={<FaEye/>}
                  title="Total Views"
                  count={view}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon={<MdUpload/>}
                  title="Total Submited"
                  count={submited}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon={<MdPercent/>}
                  title="Convertion Rate"
                  count={`${convertion}%`}
                />
              </MDBox>
            </Grid>
          </Grid>
          {no_form
            ?<Forms/>
            :<MDBox mt={4.5}>
              <MDBox sx={{
                marginTop:"60px",
                display : "flex",
                flexDirection : "row",
                alignItems : "center",
                justifyContent : "space-between"
              }}>
                <MDBox sx={{width:"50%"}}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="info"
                      title="Daily Submissions"
                      description="View a brief summary of each day"
                      date="updated 4 min ago"
                      chart={last_7_days}
                    />
                  </MDBox>
                </MDBox>
                <MDBox sx={{width:"48%"}}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="success"
                      title="Last 3 Month Submissions"
                      description="View a brief summary of each month"
                      date="campaign sent 2 days ago"
                      chart={last_3_month_submissions}
                    />
                  </MDBox>
                </MDBox>
                
              </MDBox>
            </MDBox>

          }
        </MDBox>
        
      }
    </DashboardLayout>
  );
}

export default Dashboard;
