import style from "./editor.css"
import { useState } from "react";

import { Card } from "@mui/material"
import MDButton from "components/MDButton"
import MDDropdown from "components/MDDropdown"
import Question_bulilder from "components/Question_builder"
import { MdAdd, MdCallSplit } from "react-icons/md"
import Logic from "./components/Logica/logic";
import MDBox from "components/MDBox";


const EditorCreating = (props)=>{
    return(
        <Card sx = {{width: "70%", margin: "50px auto"}}>
            <Logic
                unsaved_logics={props.unsaved_logics}
                setUnsaved_logics={props.setUnsaved_logics} 
                setLogics = {props.setLogics}
                questions = {props.questions}
                open = {props.open_logic}
                handleClose = {props.handleClose_logic}
            />
            <MDBox 
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className = "content-nav"
            >
                <MDDropdown value = {props.field_type} onChange = {(e)=>{
                    props.setField_type(e.target.value)
                }}/>
                <div>
                    {props.title.length
                        ?<MDButton onClick = {props.handleOpen_logic} color = "white" size = "small" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content", marginRight:"20px"}}>
                            <div><MdCallSplit size={20}/></div>
                            <div>Add Logic</div>
                        </MDButton>
                        :<></>
                    }   
                    <MDButton onClick = {props.append_question} color = "white" size = "small" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content"}}>
                        <div><MdAdd size={20}/></div>
                        <div>Add Question</div>
                    </MDButton>
                </div>
            </MDBox>
            <div className = "content-box">
                <Question_bulilder 
                    type = {props.field_type}
                    onChange_title = {props.onChange_title}
                    onChange_description = {props.onChange_description}
                    buttonTextValue = {props.buttonTextValue}
                    onChange_button_value = {props.onChange_button_value}
                    options = {props.options}
                    setOptions = {props.setOptions}
                    img_options = {props.img_options}
                    setImg_options = {props.setImg_options}
                    list_options = {props.list_options}
                    setList_options = {props.setList_options}
                    thankou_btn_text_value = {props.thankou_btn_text_value}
                    onChange_thankou_btn_text = {props.onChange_thankou_btn_text}
                />
            </div>
        </Card>
    )
}

export default EditorCreating