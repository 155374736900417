import style from "./multiple_choice.css"

import MDButton from "components/MDButton"
import { MdOutlineDelete} from "react-icons/md"
import { useState } from "react"
import Blanck_input from "../input/blanck_input"





function Multiple_choice(props){

    const [current_value, setCurrent_value] = useState("1")

    function add_new(){
        props.setOptions([...props.options, current_value])

        /*Limpando campos de valores que ja formaenviados*/
        setCurrent_value("")

        const input = document.getElementById("add-new-input")
        input.value = ""
        console.log(props.options)
    }


    function Option(props){

        const abcd = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
    
        return(
            <>
                {props.lista.map((item)=>{
                    return (
                        <div className="option-box">
                            <div className="index">{abcd[props.lista.indexOf(item)]}</div>
                            <Blanck_input
                                id = "blanck-input"
                                className = "medium"
                                value = {item}
                            />
                            <div className="delete-button" title = "delete">
                                <MdOutlineDelete
                                    onClick = {()=>{props.setOptions(props.lista.filter(op=> op !== item))}}
                                />
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }


    return(
        <div className="multiple-choice-box">
            <Option lista = {props.options} setOptions = {props.setOptions}/>
            <div className="add-new">
                <input
                    onChange={(e)=>{setCurrent_value(e.target.value)}}
                    id = "add-new-input"
                    type = "text"
                    placeholder = "Novo item"
                />
                <div>
                    <MDButton
                        color="dark"
                        size ="small"
                        onClick = {()=>{add_new()}}
                    >Add option</MDButton>
                </div>
            </div>
        </div>
    )
}

export default Multiple_choice