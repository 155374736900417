//BIBLIOTECA PARA BOTAO COM LOADER SPIN
import { Button as SpinButton } from "rsuite"; 
import "rsuite/dist/rsuite.min.css"; // Default CSS 

import { Box, Modal } from "@mui/material"
import PageLayout from "examples/LayoutContainers/PageLayout"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { FaWindowClose } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import { redirect, useOutlet } from "react-router-dom"
import Image_selector from "./components/image_selector/image_selctor"
import Input_text from "./components/input_2/input"
import List_selector from "./components/List_selector/list_selector"
import Multiple_choice from "./components/Multiple_choice/multiple_choice"

import style from "./quiz.css"
import ThankYou from "./components/thankyou/thankyou"
import MDTypography from "components/MDTypography"
import Satisfation_rate from "./components/satisfation_rate/satisfation_rate";

export default function Preview(props){

    const [isSubmitingn, setIsSubmiting] = useState(false)
 
    const [responses, setResponse] = useState([]) /*Conjunto de todas respostas incrementadas em forma de append*/
    const [current_response, setCurrent_response] = useState("")  /*Resposta atual que muda em cada bloco de pergunta*/
    const [tepm_response, setTepm_response] = useState("")
    const [next_question, setNext_question] = useState({})

    const [openThankyou, setOpenThankyou] = useState(false)
    const handleOpen = ()=> setOpenThankyou(true)
    const handleClose = ()=> setOpenThankyou(false)

    useEffect(() => {
        if(props.questions.length>0){
            setNext_question(props.questions[0])
            console.log(`A pergunta mudou para: ${props.questions}`);
        } else{
            setNext_question({})
        }
        
    }, [props.questions]);

   

    /*Custom custon question data*/
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")


    const input_type = ["name", "short_text", "long_text", "email", "phone", "date", "link", "number", "money", "address"]
    
    //CONFIGURANDO AS CORES DO FORMULARIO
    const buttonColor = props.buttonColor
    const buttonFontColor = props.buttonFontColor
    const questionColor = props.questionColor
    const descriptionColor = props.descriptionColor
    const responseColor = props.responseColor
    const bgColor = props.bgColor
    const backgroundImage = props.backgroundImage
    const redirect_url = props.redirect_url
    const logo = props.logo

    useLayoutEffect(() => {
        //CONFIGURANDO AS CORES DO FORMULARIO
        //document.body.style.backgroundColor = bgColor;
        //document.body.style.backgroundImage = `url(${backgroundImage})`;
        //document.body.style.backgroundSize = 'cover';
        //document.body.style.backgroundPosition = 'center';
        //document.body.style.backgroundRepeat = 'no-repeat';

        //document.getElementById("question").style.color = questionColor
        //document.getElementById("description").style.color = descriptionColor
        //document.getElementById("response").style.color = responseColor
    }, []);

    const btn_style = {
        color:buttonFontColor,
        backgroundColor:buttonColor,
        marginTop: "20px",
        minWidth: "150px",
        paddingInline:"10px",
        height: "30px",
        borderRadius: "5px",
        border: "none",
        fontSize: "medium"
    }

    const satisfation_btn_style = {
        color:buttonFontColor,
        backgroundColor:buttonColor
    }

    const backgroundStyle = {
        backgroundColor:bgColor,
        backgroundImage:`url(${backgroundImage})`,
        backgroundSize:"cover",
        backgroundPosition:"center",
        backgroundRepeat:"no-repeat"
    }

    //Logo style
    const logo_styles = {
        margin:"0px",
        padding:"0px",
        position: 'absolute',
        top: '8.5%',
        left: '4%',
        transform: 'translate(-50%, -50%)',
        width: "80px",
    };

    //Modal styles
    const styles = {
        margin:"0px",
        padding:"0px",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100vw",
        minHeight:"100vh",
        bgcolor: 'background.paper',
        borderRadius:'10px',
        boxShadow: 24
      };

    //FUNCAO PARA RESETAR O FLOW DO FORMULARO AO FECHAR O PREVIEW
    function handleClosePreview(){
        setResponse([])
        setNext_question(props.questions[0])
    }

    //FUNCAO PARA ENVIAR O FORMULARIO PARA O BACKEND
    function sendForm(){
        setIsSubmiting(true)
        const last_result = {
            name:username,
            email:email,
            phone:phone,
            quiz_id:"",
            quiz:responses
        }
        
        if(redirect_url.length>0){
            window.location.assign(redirect_url);
        } else{handleOpen()}
        
        setIsSubmiting(false)
        console.log(last_result);
    }


    //FUNCAO PARA TRATAR A LOGICA DAS PERGUNTAS
    function handleLogic(this_index){
        next_question.logic.map((logic)=>{

            let next_index = props.questions.findIndex(q=> q.title == logic.next)

            if(logic.type=="equals"){
                if(current_response == logic.text){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1]) // Se o elemento é o último na lista de logicas de uma pergunta, vai para proxima
                    }
                }
            } else if(logic.type=="different"){
                if(current_response !== logic.text){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1])
                    }
                }
            } else if(logic.type=="contains"){
                if(current_response.includes(logic.text)){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1])
                    }
                }
            } else if(logic.type=="does_not_contain"){
                if(!current_response.includes(logic.text)){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1])
                    }
                }
            } else if(logic.type=="greater_than"){
                if(current_response > logic.text){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1])
                    }
                }
            } else if(logic.type=="smaller_than"){
                if(current_response < logic.text){
                    if(next_index !== -1){setNext_question(props.questions[next_index])}
                } else{
                    if (JSON.stringify(next_question.logic[next_question.logic.length - 1]) === JSON.stringify(logic)) {
                        setNext_question(props.questions[this_index + 1])
                    }
                }
            } else {console.log("Logica nao verificada")}
        })
        
    }

    

    //FUNCAO PARA IR A PROXIMA PERGUNTA
    function NextQuestion(){
        const current_index = props.questions.findIndex(e=> JSON.stringify(e) == JSON.stringify(next_question));

        //faz commit da resposta
        setResponse([...responses, {
            question:next_question.title,
            response:current_response
        }]);

        if (next_question.logic.length>0){
            handleLogic(current_index)
        } else {
            setNext_question(props.questions[current_index+1]);
        }

        try {
            //Resetando valores
            document.getElementById("response").value = ""
        } catch (error) {
            console.log(error)
        }
    }

    //GARANTE QUE UMA FUNCAO SERA CHAMADA APENAS QUANDO UM ESTDO ESPECIFICO MUDAR
    const isFirstRender = useRef(true) //ignora a chamada automatica do useEffect quando a aplicacao e iniciada
    useEffect(()=>{
        if (isFirstRender.current){
            isFirstRender.current = false
            console.log("primeira renderizacao")
            return
        }

        NextQuestion()

    },[current_response])

   

    //pega a resposta do multiselect
    function handleMultiselct(valor){
        setCurrent_response(valor);
    } 
    
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx = {styles}>
                <div style={{backgroundColor:buttonColor}} className="preview-header">
                    <div style={{color:buttonFontColor}}>Preview Form</div>
                    <div><FaWindowClose color={buttonFontColor} onClick={()=>{
                        props.handleClose();
                        handleClosePreview();
                    }}/></div>
                </div>
                <ThankYou isOpen={openThankyou} duration={500}/>
                <img src = {logo} style={logo_styles}/>
                <div style={backgroundStyle} className="quiz-page">
                    {"title" in next_question &&
                        <div className="box">
                            <div className="pergunta">
                                <MDTypography variant="h4" fontWeight="medium" color={questionColor} id="question">{next_question.title}</MDTypography>
                                <p id="description" style={{color:descriptionColor, marginTop:"5px"}}>{next_question.description}</p>
                            </div>
                            {input_type.includes(next_question.type) &&
                                <Input_text
                                    placeholder = {next_question.placeholder}
                                    button_text = {next_question.button_text}
                                    btn_style={btn_style}
                                    onChange = {(e)=>{setTepm_response(e.target.value)}}
                                    onClick = {()=>{setCurrent_response(tepm_response)}}
                                />
                            }
                            {next_question.type == "multiple_choice" && 
                                <Multiple_choice
                                    options = {next_question.options}
                                    onClick = {e=> {
                                        setCurrent_response(e.target.value);
                                    }}
                                />
                            }
                            {next_question.type == "image" && 
                                <Image_selector
                                    options = {next_question.options}
                                    onClick = {e=> {
                                        setCurrent_response(e.target.value)
                                    }}
                                />
                            }
                            {next_question.type == "satisfation" && 
                                <Satisfation_rate
                                    style = {satisfation_btn_style}
                                    onClick = {e=>{
                                        setCurrent_response(e.target.value)
                                    }}
                                />
                            }
                            {next_question.type == "list" && 
                                <>
                                    <List_selector
                                        btn_style = {btn_style}
                                        sx={{color:"white", marginInline:"10px", borderRadius:"5px", border: "1px solid white", marginTop:"5px"}}
                                        options = {next_question.options}
                                        handleMultiselct = {handleMultiselct} //define o valor de setCurrent_response
                                    />
                                    
                                </>
                            }
                            {next_question.type == "thankyou" && 
                                <div>
                                    {isSubmitingn
                                        ?<SpinButton loading appearance="primary" style={btn_style}/>
                                        :<button style={btn_style} id="btn" onClick={sendForm}>
                                            {next_question.thankou_button_text}
                                        </button>
                                    }
                                    
                                </div>
                            }
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    )
    
}