import logo_for_white from "assets/images/logos//formchelo_for_white.png"

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import Editor from "layouts/editor";
import Profile from "layouts/profile"
import SignIn from "layouts/authentication/sign-in"
import SignUp from "layouts/authentication/sign-up"
import Aparence from "layouts/editor/components/settings/aparence/aparence";
import Metricas from "layouts/editor/components/settings/metricas/metricas";
import { DataProvider } from "layouts/dataprovider/dataprovider";
import Billing from "layouts/billing";
import PaddleCheckout from "layouts/checkout/paddleCheckout";
import Avancada from "layouts/editor/components/settings/integration/avancada";
import { VerifyEmailSent } from "layouts/verify_email/verify_email";
import { EmailVerified } from "layouts/verify_email/verify_email";
import { VerifyEmailProcess } from "layouts/verify_email/verify_email";
import { EmailVerifieLinkExpired } from "layouts/verify_email/verify_email";
import Dashboard from "layouts/dashboard";

export default function App() {


  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );


  return (
    <DataProvider> {/*MINHA IMPLEMENTACA PARA PARTILHA DE CONSTANTES ENTRE AS ROUTAS*/}
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo_for_white : logo_for_white}
              brandName="Formchelo"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="/forms/editor" element={<Editor/>}>
            <Route path="metricas" element={<Metricas/>}/>
            <Route path="avancadas" element={<Avancada/>}/>
            <Route index element={<Aparence/>}/>
          </Route>
          <Route path="/editor" element={<Editor/>}>
            <Route path="metricas" element={<Metricas/>}/>
            <Route path="avancadas" element={<Avancada/>}/>
            <Route index element={<Aparence/>}/>
          </Route>
          <Route path="/settings" element={<Profile/>}/>
          <Route path="/billing" element={<Billing/>}/>
          <Route path="/sign-in" element={<SignIn/>}/>
          <Route path="/sign-up" element={<SignUp/>}/>
          <Route path="/account" element={<PaddleCheckout/>}/>
          <Route path="/verify-email" element={<VerifyEmailProcess/>}/>
          <Route path="/email-verified" element={<EmailVerified/>}/>
          <Route path="/email-verification-expired" element={<EmailVerifieLinkExpired/>}/>
          <Route path="/email-verification-sent" element={<VerifyEmailSent/>}/>
          <Route path="/account/email-verification-sent" element={<VerifyEmailSent/>}/>
        </Routes>
      </ThemeProvider>
    </DataProvider>
  );
}
