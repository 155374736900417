import style from "./image_selector.css"


function Image_selector(props){

    const abcd = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]

    return( 
        <div className="image-content-box">
            <div className="options">
                {props.options.map((op)=>{
                    return (
                        <>
                            <div  className="image-option-box">
                                <div className="image">
                                    <input 
                                        type="image" 
                                        src={op.image}
                                        value={op.description}
                                        onClick={props.onClick}
                                    />
                                </div>
                                <div className="legenda">
                                    <div className="index">{abcd[props.options.indexOf(op)]}</div>
                                    <div className="text">{op.description}</div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default Image_selector