// src/components/PaddleScript.js
import { DataContext } from 'layouts/dataprovider/dataprovider';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function PaddleScript(){

  useEffect(() => {
    const client_token = process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN

    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
    script.async = true;
    script.onload = () => {
        window.Paddle.Initialize({ 
          token: client_token
        });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export function PaddleScriptSandbox(){

  useEffect(() => {
    const client_token = process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN

    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
    script.async = true;
    script.onload = () => {
        window.Paddle.Environment.set("sandbox");
        window.Paddle.Initialize({ 
          token: client_token
        });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

