
import style from "./input.css"

const Blanck_input = (props)=>{
    return (
        <input 
            type="text"
            style={props.sx}
            id = {props.id}
            className={props.className}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onInput={props.onInput}
        />
    )
}

export default Blanck_input


