//BIBLIOTECA PARA BOTAO COM LOADER SPIN
import { Button as SpinButton } from "rsuite"; 
import "rsuite/dist/rsuite.min.css"; // Default CSS 

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { DataContext } from "layouts/dataprovider/dataprovider";

function Cover() {
  const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env
  const privacy_policy = process.env.REACT_APP_PRIVACY_POLICY
  const refund_policy = process.env.REACT_APP_REFUND_POLICY
  const terms_and_condition = process.env.REACT_APP_TERMS_AND_CONDITION

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const {setAuthData} = useContext(DataContext)

  const [checked, setChecked] = useState(false);

  //error viasual info
  const [passwor_error, setPassword_error] = useState(false)
  const [email_error, setEmail_error] = useState(false)
  const [name_error, setName_error] = useState(false)
  const [checked_error, setChecked_error] = useState(false)

  const [isLoading, setIsLoading] = useState(false) //controlador para mostrar efeito de loading no botao 

  const navigate = useNavigate()

  useEffect(()=>{
    if(checked){setChecked_error(false)}
  },[checked])

  const handleResint = ()=>{
    if(name.length<1){setName_error(true)}
    if(email.length<1){setEmail_error(true)}
    if(email.indexOf("@") == -1){setEmail_error(true)}
    if(password.length<1){setPassword_error(true)}
    if(!checked){setChecked_error(true)}
    else{
      setIsLoading(true)
      axios.post(`${apiUrl}/v1/user/auth/sig-up`, {
        name: name,
        email: email,
        password: password

      }).then((response)=>{
        if(response.data.estado){
          setAuthData({
            email:email
          })
          navigate("/email-verification-sent")
        } else{
          console.log(response.data)
        }
        setIsLoading(false)
      })
    }
  }


  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              {name_error
                ?<MDInput error onChange={e=>setName(e.target.value)} onClick={()=>{setName_error(false)}} type="text" label="Name" variant="standard" fullWidth />
                :<MDInput onChange={e=>setName(e.target.value)} type="text" label="Name" variant="standard" fullWidth />
              }
            </MDBox>
            <MDBox mb={2}>
              {email_error
                ?<MDInput error onChange = {e=> setEmail(e.target.value)} onClick={()=>{setEmail_error(false)}} type="email" label="Email" variant="standard" fullWidth />
                :<MDInput onChange = {e=> setEmail(e.target.value)} type="email" label="Email" variant="standard" fullWidth />
              }
            </MDBox>
            <MDBox mb={2}>
              {passwor_error
                ?<MDInput error onChange = {e=> setPassword(e.target.value)} onClick={()=>{setPassword_error(false)}} type="password" label="Password" variant="standard" fullWidth />
                :<MDInput onChange = {e=> setPassword(e.target.value)} type="password" label="Password" variant="standard" fullWidth />
              }
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                checked={checked}
                onChange={e=> setChecked(e.target.checked)}
              />
              <MDTypography
                variant="caption"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the
              </MDTypography>
              <MDTypography
                component="a"
                href={terms_and_condition}
                variant="caption"
                fontWeight="bold"
                color="info"
                textGradient
              >
                &nbsp;Terms and Conditions,&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href={privacy_policy}
                variant="caption"
                fontWeight="bold"
                color="info"
                textGradient
              >
                &nbsp;Privacy Policy &
              </MDTypography>
              <MDTypography
                component="a"
                href={refund_policy}
                variant="caption"
                fontWeight="bold"
                color="info"
                textGradient
              >
                &nbsp;&nbsp;Refund Policy&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox>
              {checked_error
                ?<MDTypography sx={{textAlign:"center"}} variant="h6" size="small" color="error">
                  You must agree to the terms and conditions before creating an account. Please check the box to proceed.
                </MDTypography>
                :<></>
              }
            </MDBox>
            <MDBox mt={4} mb={1}>
              {!isLoading
                ?<MDButton onClick={handleResint} variant="gradient" color="info" fullWidth>
                  sign up
                </MDButton>
                :<SpinButton loading color="blue" appearance="primary" style={{
                  width:"100%",
                  height:"40px"
                }}/>
              }
              
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
