
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import { FormsLayout } from "layouts/forms";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Forms",
    key: "forms",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/forms",
    component: <FormsLayout/>,
  },
];

export default routes;
