import style from "./logic.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { MdAddBox, MdCallSplit, MdClose, MdDelete, MdSave } from 'react-icons/md';
import MDInput from "components/MDInput";
import {useState } from "react";
import Condition_Dropdown from "components/MDDropdown/condition_dropdown";
import Question_list_Dropdown from "components/MDDropdown/question_list_dropdown";

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  minHeight:"400px",
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

function Logic_update(props){

  
  const [logic_type, setLogic_type] = useState("")
  const [logic_text, setLogic_text] = useState("")
  const [logic_next, setLogic_next] = useState("")

  const handleSave = ()=>{
    const updatedQuestionLogic = props.questions.map((q, i) => {
    if (i === props.qIndex) {
        // Selected and updated question
        return {
            "type":q.type,
            "title":q.title,
            "description":q.description, 
            "options":q.options, 
            "logic":props.unsaved_logics //mantem o resto e atualiza a logica  
        };
    } else {
        // The rest haven't changed
        return q;
    }
    });
    props.setQuestions(updatedQuestionLogic);

    props.setUnsaved_logics([]);
    props.handleClose() /*Fecha o modal*/;
  }

  const Add_logic =()=>{
    props.setUnsaved_logics([...props.unsaved_logics, 
      {"type":logic_type,
      "text":logic_text,
      "next":logic_next}
    ])
    /*Zerando valores*/
    setLogic_type("");
    setLogic_text("");
    setLogic_next("");
    const elemento = document.getElementById("input-value");
    elemento.value = "";

  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className='logic-box'>
            <div className='header'>
              <div><MdCallSplit size ={20}/></div>
              <>
                <div className="save"><MdSave onClick={handleSave} id="save" size ={20}/></div>
                <div className="save"><MdClose onClick={()=>props.handleClose()} id="close" size ={20}/></div>
              </>
            </div>
            <div className='content'>
              <div className="nav">
                <div>Se a resposta deste campo</div>
                <div>Pular para...</div>
              </div>
              <div className="main">
                {props.unsaved_logics.length>0 && props.unsaved_logics.map(item=>{
                  return(
                    <div className="exist">
                      <div id = "condition"><MDInput desabled value = {item.type} placeholder = "value" sx={{width:"100%"}}/></div>
                      <div id = "value"><MDInput value = {item.text} placeholder = "value" sx={{width:"100%"}}/></div>
                      <div id = "next-q">
                        <div className="to"><MDInput value = {item.next} placeholder = "value" sx={{width:"100%"}}/></div>
                        <MdDelete
                          onClick={()=>{props.setUnsaved_logics(props.unsaved_logics.filter(op=> op !== item))}}
                          title="delete"
                          className="delete" 
                          size={25} />
                      </div>
                    </div>
                  )
                })
                }
                <div className="add-new">
                  <div id = "condition">
                    <Condition_Dropdown
                      onChange = {e=>setLogic_type(e.target.value)}
                      value = {logic_type}
                      label = "Condition"
                    />
                  </div>
                  <div id = "value">
                    <MDInput
                      onChange = {e=>setLogic_text(e.target.value)}
                      id = "input-value"
                      placeholder = "value" 
                      sx={{width:"100%"}}
                    />
                  </div>
                  <div id = "next-q">
                    <div className="to">
                      <Question_list_Dropdown 
                        onChange = {e=>setLogic_next(e.target.value)}
                        value = {logic_next}
                        lista = {props.questions}
                        label = "Go To"
                      />
                    </div>
                    <MdAddBox onClick = {Add_logic} title="add" className="add" size={25} />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default  Logic_update