import style from "../metricas/metricas.css"
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useOutletContext } from "react-router-dom";
import Plataform from "../metricas/plataform";
import { useContext } from "react";
import { DataContext } from "layouts/dataprovider/dataprovider";

export default function Avancada(){
    const {permitionData} = useContext(DataContext)

    const [
        buttonColor, 
        setButtonColor,
        buttonFontColor,
        setButtonFontColor,
        questionColor,
        setQuestionColor,
        descriptionColor,
        setDescriptionColor,
        responseColor,
        setResponseColor,
        bgColor,
        setBgColor,
        backgroundImage,
        setBackgroundImage,
        logo,
        setLogo,
        form_name,
        setForm_name,
        facebookId, setFacebookId,
        googleTag, setGoogleTag,
        googleAnalytics, setGoogleAnalytics,
        webhook_url, setWebhook_url,
        redirect_url, setRedirect_url
    ] = useOutletContext()

    const webhook_state = ()=>{
        return permitionData.includes("Webhooks")
    }
    
    const redirect_state = ()=>{
        return permitionData.includes("Redirect_on_completion")
    }

    return(
        <div className="metrica-box">
            <div className="content">
                <div className="box">
                    <div className="header">
                        <MDTypography variant="h4" fontWeight="medium" color="black">Post-Submission Actions</MDTypography>
                        <MDTypography variant="h6" fontSize="small" color="text">
                            Configure what happens after a response is submitted. Set up webhooks and redirect links to guide users accordingly.
                        </MDTypography>
                    </div>
                    <Plataform
                        name = "Webhook URL"
                        avaliable = {webhook_state()}
                        description = "Set the webhook URL to receive notifications of new responses."
                        value = {webhook_url}
                        onChange = {e=>setWebhook_url(e.target.value)}
                    />
                    <Plataform
                        name = "Redirect Link"
                        avaliable = {redirect_state()}
                        description = "Set up a link to redirect users after form submission."
                        value = {redirect_url}
                        onChange = {e=>setRedirect_url(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}