import style from "./input.css"

const Underline_input = (props)=>{
    return(
        <div style={{marginTop:"25px"}}>
            <input
                disabled
                id="underline-input"
                className={props.className}
                type={props.type}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
            <hr/>
        </div>
    )
}

export default Underline_input