import style from "./setting.css"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { MdAddBox, MdCallSplit, MdClose, MdDelete, MdSave, MdSettings } from 'react-icons/md';
import MDInput from "components/MDInput";
import {useState } from "react";
import { Outlet } from "react-router-dom";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom"


const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  minHeight:"80%",
  bgcolor: 'background.paper',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

function Setting(props){

  let navigate = useNavigate()

  const [buttonColor, setButtonColor] = [props.buttonColor, props.setButtonColor]
  const [buttonFontColor, setButtonFontColor] = [props.buttonFontColor, props.setButtonFontColor]
  const [questionColor, setQuestionColor] = [props.questionColor, props.setQuestionColor]
  const [descriptionColor, setDescriptionColor] = [props.descriptionColor, props.setDescriptionColor]
  const [responseColor, setResponseColor] = [props.responseColor, props.setResponseColor]
  const [bgColor, setBgColor] = [props.bgColor, props.setBgColor]
  const [backgroundImage, setBackgroundImage] = [props.backgroundImage, props.setBackgroundImage]
  const [logo, setLogo] = [props.logo, props.setLogo] 
  
  const [form_name, setForm_name] = [props.form_name, props.setForm_name]

  const [facebookId, setFacebookId] = [props.facebookId, props.setFacebookId]
  const [googleTag, setGoogleTag] = [props.googleTag, props.setGoogleTag]
  const [googleAnalytics, setGoogleAnalytics] = [props.googleAnalytics, props.setGoogleAnalytics]
  const [webhook_url, setWebhook_url] = [props.webhook_url, props.setWebhook_url]
  const [redirect_url, setRedirect_url] = [props.redirect_url, props.setRedirect_url]

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
            <div className="setting-box">
                <div className="s-header">
                    <div className="icon">
                        <MdSettings/>
                    </div>
                    <div className="nav-link">
                        <MDButton onClick={()=>navigate("./")} color = "dark" size = "small" variant = "outlined">Appearance</MDButton>
                        <MDButton onClick={()=>navigate("metricas")} color = "dark" size = "small" variant = "outlined">Metrics</MDButton>
                        <MDButton onClick={()=>navigate("avancadas")} color = "dark" size = "small" variant = "outlined">More</MDButton>
                    </div>
                    <div className="action">
                        <div className=""><MdClose onClick={props.toClose}/></div>
                    </div>
                </div>
                <div className="s-body">
                    <Outlet context={[
                      buttonColor, 
                      setButtonColor,
                      buttonFontColor,
                      setButtonFontColor,
                      questionColor,
                      setQuestionColor,
                      descriptionColor,
                      setDescriptionColor,
                      responseColor,
                      setResponseColor,
                      bgColor,
                      setBgColor,
                      backgroundImage,
                      setBackgroundImage,
                      logo,
                      setLogo,
                      form_name,
                      setForm_name,
                      facebookId, setFacebookId,
                      googleTag, setGoogleTag,
                      googleAnalytics, setGoogleAnalytics,
                      webhook_url, setWebhook_url,
                      redirect_url, setRedirect_url
                    ]}/>
                </div>
            </div>
          
        </Box>
      </Modal>
    </div>
  );
}

export default  Setting