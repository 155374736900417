import style from "./satisfation.css"

function Satisfation_rate(props){

    const Option = (props) =>{
        return <input style={props.style} id = "satisfation-option" type = "button" onClick={props.onClick} value = {props.value}/>
    }
    
    return (
        <div className="satisfation-box">
            <Option value ="0" onClick={props.onClick} style = {props.style}/>
            <Option value ="1" onClick={props.onClick} style = {props.style}/>
            <Option value ="2" onClick={props.onClick} style = {props.style}/>
            <Option value ="3" onClick={props.onClick} style = {props.style}/>
            <Option value ="4" onClick={props.onClick} style = {props.style}/>
            <Option value ="5" onClick={props.onClick} style = {props.style}/>
            <Option value ="6" onClick={props.onClick} style = {props.style}/>
            <Option value ="7" onClick={props.onClick} style = {props.style}/>
            <Option value ="8" onClick={props.onClick} style = {props.style}/>
            <Option value ="9" onClick={props.onClick} style = {props.style}/>
            <Option value ="10" onClick={props.onClick} style = {props.style}/>
        </div>
    )
}
export default Satisfation_rate