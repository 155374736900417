import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function Question_list_Dropdown(props){

  return (
    <div>
      <FormControl sx={{width: "100%"}}>
        <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel>
        <Select
            sx = {{height:"40px"}}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={props.value}
            onChange={props.onChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
        >
          {props.lista.map((name) => (
            <MenuItem key={name.title} value={name.title}>
              {name.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default  Question_list_Dropdown