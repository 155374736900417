import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [form_listData, setForm_listData] = useState({});
  const [authData, setAuthData] = useState({});
  const [permitionData, setPermitionData] = useState({})

  return (
    <DataContext.Provider value={{
      formData, setFormData,
      form_listData, setForm_listData,
      authData, setAuthData,
      permitionData, setPermitionData
    }}>
      {children}
    </DataContext.Provider>
  );
};

