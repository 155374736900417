import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useContext, useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { Card, Divider, Grid, Typography } from "@mui/material";
import MDBox from 'components/MDBox';
import { MdArrowLeft, MdClose } from 'react-icons/md';
import BackgroundLetterAvatars from 'layouts/forms/avatar';
import { DataContext } from 'layouts/dataprovider/dataprovider';
import { FaArrowLeft, FaRegFilePdf } from 'react-icons/fa';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ResponseItem = (props)=>{
    return(
        <MDButton sx = {{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            textAlign:"left",
            border:"1px solid grey", borderRadius:"5px",
            width:"100%",marginBottom:"10px", height:"auto"

        }}>
            <MDBox>
                <MDTypography sx={{marginInline:"10px"}} variant="h6" color="dark">{props.question}</MDTypography>
                <MDTypography  sx={{marginInline:"10px", textWrap: "wrap"}} variant="h6"  color="text">{props.response}</MDTypography>
            </MDBox>
        </MDButton>
    )
}


export default function ResponseFlow(props){
    const responses = props.responses
    const {formData} = useContext(DataContext)

    const PDFContent = [
        { text: formData.FORM_NAME, style: 'header' },
        'This is a simple PDF document generated with pdfmake.',
        {text: "", style:'customBotton'}
    ] 

    const generatePDF = () => {
        // Defina o conteúdo do documento
        const docDefinition = {
          content: PDFContent,
          styles: {
            header: {
              fontSize: 22,
              bold: true,
            },
            subheader: {
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 5],
              marginTop:20
            },
            customBotton:{
                marginBottom:30
            }
          }
        };
    
        // Gere o PDF e faça o download
        pdfMake.createPdf(docDefinition).download(`${formData.FORM_NAME}.pdf`);
    };
    
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={props.styles}>
                    <MDBox pt={1} sx={{height:"70vh", width:"100%"}}>
                        <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                            <MDBox sx = {{display:"flex", alignItems:"center"}}>
                                <MDButton size="small" color = "dark" variant="outlined" onClick={props.onClickClose} title="close" sx={{width:"fit-content", marginRight:"15px"}}><FaArrowLeft/></MDButton>
                                <FaRegFilePdf onClick={generatePDF} size={30}/>
                            </MDBox>
                            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                <MDTypography sx={{marginInline:"10px"}} variant="h6" color="dark">{formData.FORM_NAME}</MDTypography>
                                <BackgroundLetterAvatars name = {formData.FORM_NAME}/>
                            </MDBox>
                        </MDBox>
                        <Divider/>
                        <MDBox sx={{overflow: "auto", maxHeight:"60vh"}}>
                            <>
                                {responses.length> 0 &&
                                    responses.map((res)=>{
                                        PDFContent.push(
                                            { text: res.question, style: 'subheader' },
                                            res.response
                                        )
                                        return <ResponseItem question = {res.question} response={res.response}/>
                                    })
                                }
                            </>
                        </MDBox>
                    </MDBox>
                </Box>
            </Modal>
        </div>
    );
}
