
import style from "./input.css"

const Blanck_input = (props)=>{
    return (
        <input 
            type="button"
            style={props.sx}
            id = {props.id}
            className={props.className}
            value={props.value}
            placeholder={props.placeholder}
            onClick={props.onClick}
            onInput={props.onInput}
        />
    )
}

export default Blanck_input


