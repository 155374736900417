import style from "./aparence.css"

import MDTypography from "components/MDTypography";
import ColorPicker from "./color";
import { useState } from "react";
import MDButton from "components/MDButton";
import { MdOutlineDelete, MdUpload, MdUploadFile } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

export default function Aparence(){

    const [
        buttonColor, 
        setButtonColor,
        buttonFontColor,
        setButtonFontColor,
        questionColor,
        setQuestionColor,
        descriptionColor,
        setDescriptionColor,
        responseColor,
        setResponseColor,
        bgColor,
        setBgColor,
        backgroundImage,
        setBackgroundImage,
        logo,
        setLogo,
        form_name,
        setForm_name,
        facebookId, setFacebookId,
        googleTag, setGoogleTag,
        googleAnalytics, setGoogleAnalytics,
        webhook_url, setWebhook_url,
        redirect_url, setRedirect_url
    ] = useOutletContext()
    
    function selectImage() {
        return new Promise((resolve, reject) => {
            // Criar um input do tipo file
            var input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*'; // Aceitar apenas arquivos de imagem
    
            // Adicionar um evento de mudança para o input
            input.addEventListener('change', function(event) {
                var file = event.target.files[0]; // Obter o arquivo selecionado
    
                // Verificar se o arquivo é uma imagem
                if (file && file.type.startsWith('image/')) {
                    // Ler o conteúdo do arquivo como uma URL de dados
                    var reader = new FileReader();
                    reader.onload = function() {
                        // Resolver a promise com a URL da imagem
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    // Se o arquivo não for uma imagem, rejeitar a promise
                    reject(new Error('Por favor, selecione um arquivo de imagem válido.'));
                }
            });
    
            // Disparar o clique no input para abrir o seletor de arquivos
            input.click();
        });
    }

    return(
        <div className="aparence-box">
            <div className="content">
                <div style={{marginBottom:"5px"}}>
                    <MDTypography variant="h6" fontWeight="medium" color="black">Form name</MDTypography>
                    <MDTypography variant="h6" fontSize="small" color="text">Change your form name here</MDTypography>
                    <MDInput
                        sx={{minWidth:"100%"}}
                        value = {form_name}
                        onChange = {e=>setForm_name(e.target.value)}
                    />
                </div>
                {/**<div style={{marginBottom:"5px"}}>
                    <MDBox sx = {{width:"100%", height:"45px", border:"1px #d9d9d9 solid", borderRadius:"5px", padding:"8px 10px"}}>
                        <MDTypography variant="h6" color="text">Remove our brand</MDTypography>
                    </MDBox>
                </div>**/}
                <div className="box1">
                    <MDTypography variant="h6" fontWeight="medium" color="black">Customize Style</MDTypography>
                    <MDTypography variant="h6" fontSize="small" color="text">Add custom colors to match your brand.</MDTypography>
                    <ColorPicker
                        text="Button Color:"
                        value={buttonColor}
                        onChange={e=>setButtonColor(e.target.value)}
                    />
                    <ColorPicker
                        text="Button Text Color:"
                        value={buttonFontColor}
                        onChange={e=>setButtonFontColor(e.target.value)}
                    />
                    <ColorPicker
                        text="Question Color:"
                        value={questionColor}
                        onChange={e=>setQuestionColor(e.target.value)}
                    />
                    <ColorPicker
                        text="Description Color:"
                        value={descriptionColor}
                        onChange={e=>setDescriptionColor(e.target.value)}
                    />
                    <ColorPicker
                        text="Answer Color:"
                        value={responseColor}
                        onChange={e=>setResponseColor(e.target.value)}
                    />
                    <ColorPicker
                        text="Background Color:"
                        value={bgColor}
                        onChange={e=>setBgColor(e.target.value)}
                    />
                </div>
                
                <div className="image-box">
                    <div className="bg-image">
                        <MDTypography variant="h6" fontWeight="medium" color="black">Background Image:</MDTypography>
                        {backgroundImage.length>0
                            ?<div>
                                <div className="delete">
                                    <MdOutlineDelete
                                        onClick={()=>setBackgroundImage("")}
                                        title="delete"
                                    />
                                </div>
                                <div className="image">
                                    <img src={backgroundImage} onClick={()=>{
                                        selectImage()
                                        .then((imagePath)=>setBackgroundImage(imagePath))
                                        .catch((error) => {console.error(error.message)})
                                    }}/>
                                </div>
                            </div>
                        
                            :<MDButton color = "dark" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content"}} onClick={()=>{
                                selectImage()
                                .then((imagePath)=>setBackgroundImage(imagePath))
                                .catch((error) => {console.error(error.message)})
                            }}>
                                <MdUpload size={20}/>
                                <MDTypography variant="p" fontSize="medium" color="primery">Select a File</MDTypography>
                            </MDButton>
                        }
                    </div>
                    <div className="bg-image">
                        <MDTypography variant="h6" fontWeight="medium" color="black">Logo:</MDTypography>
                        {logo.length>0
                            ?<div>
                                <div className="delete">
                                    <MdOutlineDelete
                                        onClick={()=>setLogo("")}
                                        title="delete"
                                    />
                                </div>
                                <div className="image">
                                    <img src={logo} onClick={()=>{
                                        selectImage()
                                        .then((imagePath)=>setLogo(imagePath))
                                        .catch((error) => {console.error(error.message)})
                                    }}/>
                                </div>
                            </div>
                        
                            :<MDButton color = "dark" size = "small" variant = "outlined" sx ={{display:"flex", alignItems:"center", justifyContent:"center", height:"min-content"}} onClick={()=>{
                                selectImage()
                                .then((imagePath)=>setLogo(imagePath))
                                .catch((error) => {console.error(error.message)})
                            }}>
                                <MdUpload size={20}/>
                                <MDTypography variant="p" fontSize="medium" color="primery">Select a File</MDTypography>
                            </MDButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}