import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import imagem from "assets/images/no_response.png"
import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "layouts/dataprovider/dataprovider";
import MDInput from "components/MDInput";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";




export default function NOResponse(props){
    const {formData} = useContext(DataContext)
    const [copiado, setCopiado] = useState(false)
    const inputRef = useRef(null);

    const copyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(formData.FORM_URL);
          props.close()
          
        } catch (err) {
          console.error('Falha ao copiar: ', err);
        }
      };
    
    const copyEmbedCode = async () => {
        try {
            await navigator.clipboard.writeText(
            `
            <iframe src=${formData.FORM_URL} width="100%" height="100%" style="border:none; margin: 0px;">
            </iframe>
            `
            );
            props.close()
            //toast.success('Texto copiado: ' + formData.FORM_URL); utilizado para mostrar notificacao bonita
        } catch (err) {
            console.error('Falha ao copiar: ', err);
        }
        };

    const shareOnfacebook = ()=>{
        window.location.assign(`https://www.facebook.com/sharer/sharer.php?u=${formData.FORM_URL}`)
    }

    const shareOnTwitter = ()=>{
        window.location.assign(`https://twitter.com/intent/tweet?text=${formData.FORM_URL}`)
    }
    const shareOnLinkedin = ()=>{
        window.location.assign(`https://www.linkedin.com/shareArticle/?url=${formData.FORM_URL}`)
    }

    return(
        <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center", width:"400px", margin:"30px auto"}}>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <img src = {imagem} style={{width:"250px"}}/>
            </MDBox>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <MDTypography variant="h6" color="dark" sx={{textAlign:"center"}}>
                    This form has no responses yet<br/>
                    Share it to start receiving responses
                </MDTypography>
            </MDBox>
            <MDBox>
                <MDInput
                    ref={inputRef}  
                    value = {formData.FORM_URL}
                    sx = {{width:"100%", BorderColor:"blue", marginTop:"20px"}}
                />
                <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignContent:"center", width:"100%"}}>
                    <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"space-between",  width:"40%", margin:"auto"}}>
                        <FaFacebook onClick={shareOnfacebook} title="share on Facebook"/>
                        <FaTwitter onClick={shareOnTwitter} title="share on Twitter"/>
                        <FaLinkedin onClick={shareOnLinkedin} title="share on Linkedin"/>
                        <MdContentCopy onClick={copyToClipboard} title="copy link"/>
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", marginTop:"20px"}}>
                <MDButton onClick = {copyEmbedCode} variant="gradient" color="info" sx={{width:"100%"}}>
                    Copy enbed code
                </MDButton>
            </MDBox>
        </MDBox>
    )
}