

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {useContext, useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { Card, Divider, Grid, Typography } from "@mui/material";
import MDBox from 'components/MDBox';
import { MdClose, MdLock, MdLooks, MdLooks3 } from 'react-icons/md';
import BackgroundLetterAvatars from 'layouts/forms/avatar';
import { DataContext } from 'layouts/dataprovider/dataprovider';
import axios from 'axios';
import ResponseFlow from './responseFlow';
import NOResponse from './no-reponse';
import Upgrade_to_unlock_response from './upgrade/upgrade_response';

const styles = {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "700px",
    minHeight:"400px",
    bgcolor: 'background.paper',
    borde:'none',
    borderRadius:'10px',
    boxShadow: 24,
    p: 4,
};


const ResponseItem = (props)=>{

    const primeiro_x_caracteres = (text, leng)=>{
        if (text.length<leng){
            return text.substring(0, leng)
        } else {
            return text.substring(0, leng)+"..."
        }
    }

    return(
        <MDButton onClick = {props.onClick} sx = {{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            textAlign:"left",
            border:"1px solid grey", borderRadius:"5px",
            width:"100%",marginBottom:"10px"
        }}
        >
            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                <MDBox sx={{width:"20px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {props.reponse_id == "NOT_ALLOWED"
                        ?<MdLock size={25}/>
                        :<MDTypography variant="h6" color="dark">{props.lista.indexOf(props.object) + 1}</MDTypography>
                    }
                    
                </MDBox>
                <MDBox>
                    <MDTypography sx={{marginInline:"10px"}} variant="h6" color="dark">{primeiro_x_caracteres(props.question, 50)}</MDTypography>
                    <MDTypography sx={{marginInline:"10px"}} variant="h6"  color="text">{primeiro_x_caracteres(props.response, 50)}</MDTypography>
                </MDBox>
            </MDBox>
            <Divider/>
            <MDBox>
                <MDTypography sx={{marginInline:"10px"}} variant="h6" color="dark">{props.date}</MDTypography>
            </MDBox>
        </MDButton>
    )
}


export default function Responses(props){
    const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env

    const [responses, setResponses] = useState([])
    const [selected_res, setSelected_res] = useState([])
    const {formData} = useContext(DataContext)
    const [openResponseFlow, setOpenResponseFlow] = useState(false)

    const [upgrade, setUpgrade] = useState(false)

    const localToken = localStorage.getItem("formchelo004")

    useEffect(()=>{
        if(props.open){
            axios.post(`${apiUrl}/v1/client/response/get`, {
                form_id:formData.FORMID,
                token:localToken
            })
            .then((res)=>{
                if(res.data.estado){
                    setResponses(res.data.response)
                } else {
                    setResponses([])
                    console.log(res.data)
                }
            })
            .catch((error)=> console.log(error))
        } else{console.log("Elemento de reposta nao montado")}
    }, [props.open])

    const handleOpenResponseFlow = (data)=>{
        setSelected_res(data);
        setOpenResponseFlow(true)
        console.log(data);

    }

    const handleUpgrade = ()=>{
        setUpgrade(true)
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                <Box sx={styles}>
                    <Upgrade_to_unlock_response
                        open={upgrade}
                        close={()=>setUpgrade(false)}
                        onClose = ""
                    />
                    <ResponseFlow
                        open={openResponseFlow}
                        styles = {styles}
                        responses = {selected_res}
                        onClickClose = {()=> setOpenResponseFlow(false)}
                    />
                    <MDBox pt={1} sx={{height:"70vh", width:"100%"}}>
                        <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                            <MDBox sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                <BackgroundLetterAvatars name = {formData.FORM_NAME}/>
                                <MDTypography sx={{marginInline:"10px"}} variant="h6" color="dark">{formData.FORM_NAME}</MDTypography>
                            </MDBox>
                            <MDButton color = "dark" variant="outlined" onClick={()=>{
                                setResponses([])
                                props.onClickClose()
                                }} 
                                title="close"
                            ><MdClose /></MDButton>
                        </MDBox>
                        <Divider/>
                        <MDBox sx={{overflow: "auto", maxHeight:"60vh"}}>
                            {responses.length> 0 
                                ?<>
                                    {responses.map((res)=>{
                                
                                        try {
                                            let str = res.RESPONSE_FLOW
                                            str = str.replace(/'/g, '"')
                                            let perguntas = JSON.parse(str)

                                            if(res.RESPONSEID === "NOT_ALLOWED"){
                                                return (
                                                    <ResponseItem 
                                                        onClick = {()=>handleUpgrade()}
                                                        question = {perguntas[0].question}
                                                        response={perguntas[0].response}
                                                        date = {res.RESPONSE_DATE}
                                                        lista = {responses}
                                                        object={res}
                                                        reponse_id = {res.RESPONSEID}
                                                    />
                                                )
                                            } else{
                                                return (
                                                    <ResponseItem 
                                                        onClick = {()=>handleOpenResponseFlow(perguntas)}
                                                        question = {perguntas[0].question}
                                                        response={perguntas[0].response}
                                                        date = {res.RESPONSE_DATE}
                                                        lista = {responses}
                                                        object={res}
                                                        reponse_id = {res.RESPONSEID}
                                                    />
                                                )
                                            }

                                            
                                                
                                        } catch (error) {
                                            console.log(error);
                                        }   
                                    })
                                    }
                                </>
                                :<NOResponse close={()=>props.onClickClose()}/>
                            }
                            
                        </MDBox>
                    </MDBox>
                </Box>
            </Modal>
        </div>
    );
}
