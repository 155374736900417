import style from "./thankyou.css"

import {FaPaperPlane} from "react-icons/fa"
import Blanck_input from "../input/blanck_input"
import MDInput from "components/MDInput"



function ThankYou(props){
    return(
        <div className ="thank-you">
            <FaPaperPlane size = {50} className = "icon"/>
            <div>
                <Blanck_input
                    id = "titulo"
                    className = "titulo"
                    value = {props.titleValue}
                    placeholder = "Type your question here..."
                    onChange = {props.onChange_title}
                />
                <Blanck_input
                    id = "descricao"
                    className = "descricao"
                    value = {props.descriptionValue}
                    placeholder = "Describe your question here..."
                    onChange = {props.onChange_description}
                />
                <MDInput
                    value = {props.thankou_btn_text_value}
                    onChange = {props.onChange_thankou_btn_text}
                    sx={{
                        display:"flex",
                        justifyContent:"center",
                        margin:"0px auto",
                        marginTop:"20px",
                        fontWeight:"bolder",
                        fontSize:"larger",
                        textAlign:"center",
                        width:"fit-content"
                    }}
                />
            </div>
        </div>
    )
}

export default ThankYou