import style from "./color.css"

import MDTypography from "components/MDTypography";

export default function ColorPicker(props){
    return(
        <div className="cor-line">
            <MDTypography className="name" variant="p" fontSize="medium" color="black">
                {props.text}
            </MDTypography>
            <div className="selector">
                <input
                    value={props.value}
                    onChange={props.onChange}
                    className="color-prev"
                    type="color"
                />
                <input
                    value={props.value}
                    onChange={props.onChange}
                    className="color-input" 
                    type="text"
                />
            </div>
        </div>
    )
}