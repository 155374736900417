import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Condition_Dropdown(props){

  return (
    <div>
      <FormControl sx={{width: "100%"}}>
        <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel>
        <Select
            sx = {{height:"40px"}}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={props.value}
            onChange={props.onChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
        >
          <MenuItem  key="equals" value="equals">is equal to</MenuItem>
          <MenuItem  key="different" value="different">is not equal to</MenuItem>
          <MenuItem  key="contains" value="contains">contains</MenuItem>
          <MenuItem  key="does_not_contain" value="does_not_contain">does not contain</MenuItem>
          <MenuItem  key="greater_than" value="greater_than">is greater than</MenuItem>
          <MenuItem  key="smaller_than" value="smaller_than">is less than</MenuItem>
          
        </Select>
      </FormControl>
    </div>
  );
}

export default  Condition_Dropdown