//BIBLIOTECA PARA BOTAO COM LOADER SPIN
import { Button as SpinButton } from "rsuite"; 
import "rsuite/dist/rsuite.min.css"; // Default CSS 

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";

function Basic() {
  const apiUrl = process.env.REACT_APP_API_URL //URL do server pegado da .env

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [isLoading, setIsLoading] = useState(false) //controlador para mostrar efeito de loading no botao 

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  //error viasual info
  const [passwor_error, setPassword_error] = useState(false)
  const [password_corect, setPasswordCorect] = useState(false)
  const [email_error, setEmail_error] = useState(false)
  const [email_corect, setEmail_corect] = useState(false)

  const navigate = useNavigate()

  const handleSing = () =>{
    
    if(email.length<1){setEmail_error(true)}
    else if(password.length<1){setPassword_error(true)}
    else{
      setIsLoading(true);
      axios.post(`${apiUrl}/v1/user/auth/login`, {
        email: email,
        password: password
      }).then((result)=>{
        if(result.data.estado){
          localStorage.setItem("formchelo004", result.data.token)
          navigate("/")
        } else {
          if(result.data.type == "email"){setEmail_error(true)}
          if(result.data.type == "password"){setPassword_error(true)}
        }
        setIsLoading(false)
      })
    }
    
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange = {e=> setEmail(e.target.value)} type="email" label="Email" fullWidth sx={{borderColor:"red", borderWidth:"2px"}}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange = {e=> setPassword(e.target.value)} type="password" label="Password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {email_error ^ passwor_error 
                ?<MDTypography sx={{textAlign:"center"}} variant="h6" size="small" color="error">Email or password is incorrect, please try again.</MDTypography>
                :<></>
              }
              {!isLoading
                ?<MDButton onClick={handleSing} variant="gradient" color="info" fullWidth>
                  sign in
                </MDButton>
                :<SpinButton loading color="blue" appearance="primary" style={{
                  width:"100%",
                  height:"40px"
                }}/>
              }
              
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
