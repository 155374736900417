import "./styles.css";
import logo from "../../../../assets/images/logos/formchelo_for_white.png"
import { Transition } from "react-transition-group";
import animationData from  "./animation.json"

import ModalBackdrop from "./modal-backdrop.js";
import Sidebar from "./sidebar.js";

import MDBox from "components/MDBox/index.js";
import Lottie from "lottie-react";
import MDTypography from "components/MDTypography/index.js";
import MDButton from "components/MDButton/index.js";
import { Navigate } from "react-router-dom";


export default function ThankYou(props){
    return(
        <Transition in={props.isOpen} timeout={props.duration}>
            {state => (
            <ModalBackdrop duration={props.duration} state={state}>
                <Sidebar duration={props.duration} state={state}>
                    {props.isOpen && (
                        <MDBox sx={{height:"100vh", backgroundColor:"#d6e5ff", display:"flex", flexDirection:"column", justifyContent:"space-between", margin:"0px auto"}}>
                            <MDBox><img src = {logo} style={{height:"70px", margin:"10px"}}/></MDBox>
                            <MDBox sx={{width:"400px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", margin:"0px auto"}}>
                                <MDBox><Lottie animationData={animationData} style={{ height: 485 }}/></MDBox>
                                <MDTypography sx={{marginInline:"10px", textAlign:"center"}} variant="h4" color="dark">
                                    Create amazing forms like this in 5 minutes.
                                </MDTypography>
                                <MDButton href="https://formchelo.com" sx={{width:"100%", marginTop:"20px"}} variant = "gradient" color = "info">
                                    <MDTypography variant="h6" color="white">Create Free Account</MDTypography>
                                </MDButton>
                            </MDBox>
                            <MDButton href="https://formchelo.com/pricin-plan/" size="small">
                                <MDTypography variant="h6" color="dark">Subscribe to a paid plan to remove this message. </MDTypography>
                                <MDTypography  sx={{marginLeft:"10px", border:"2px solid blue", paddingInline:"10px"}} variant="h6" color="dark">SUBSCRIBE NOW</MDTypography>
                            </MDButton>
                        </MDBox>
                    )}
                </Sidebar>
            </ModalBackdrop>
            )}
        </Transition>
    )
}